<template>
<div class="top-header">
    <b-container class="mt-5 mb-5">
        <div class="bg-white p-4 p-md-5 shadow">
        <page-show :alias="'about'"></page-show>   
    </div>
    </b-container>
    <div v-if="settingscontacts" class="pt-0 pt-md-5">
        <map-2gis :link="settingscontacts[0].value" v-if="settingscontacts[0] && settingscontacts[0].value"></map-2gis>
    </div>
</div>    
</template>

<script>
import Map from "@/components/components/Map";
import PageShow from "@/components/components/PageShow";
import Authentication from "@/components/pages/Authentication";
export default {
    props:['settings'],
    data() {
    return {
        settingscontacts:null
    };
  },
    components:{
        'map-2gis': Map,
        'page-show':PageShow,
    },
    created() {
        this.getSettings();
    },
    methods:{
    async getSettings() {
      let { data } = await Authentication.sendRequestNotAuth(
        this,
        "/api/v1/getcontactsssettings",
        "get",
      );
      if (data) {
        this.settingscontacts = data;
      }
    },
}
}
</script>

<style lang="scss">

</style>
