<template>
  <div class="card-product" v-if="stockproduct">     
    <div class="card-product-info" :class="{'price-bottom': !admin}">
      <div :class="{'modal-slider': show_modal}">
            <div class="modal-close" v-if="show_modal" @click="showModal(false)"><b-icon icon="X"></b-icon></div>
            <div class="slider-block position-relative">
              
      <b-carousel v-if="this.stockproduct.images.length>0"
        id="carousel-no-animation"
        :controls="this.stockproduct.images.length > 1"
        :indicators="this.stockproduct.images.length > 1"
        :interval="0"
      >
        <b-carousel-slide
          v-for="image in this.stockproduct.images"
          :key="image.id"
        >
        <img slot="img" class="d-block img-fluid w-100"
             :src="download(image.id)" alt="image slot" @click="showModal(true)">  
      </b-carousel-slide>
      </b-carousel>
      <b-carousel v-else
        id="carousel-no-animation"
        :controls="false"
        :indicators="false"
        :interval="0"
      >
        <b-carousel-slide
          img-src="/img/no-photo.png"
        ></b-carousel-slide>
      </b-carousel>
      <div class="gray-photo" @click="showModal(true)">               
              </div>
      </div>
    </div>
      <div class="m-3">
        <div class="card-name">
            <b>{{ stockproduct.name }}</b>
        </div>
        <div class="card-description my-2 ql-editor  ql-viewer" v-html="stockproduct.description"></div>     
     
      <div class="card-price">
          <b>{{ pricefromproduct }} </b>   <span class="ql-editor  ql-viewer ql-inline" v-html="settings.valutastock"></span>         
      </div> 
      </div>
             
    </div>   
    <div class="card-product-buttons-admin" v-if="admin">    
      <b-row no-gutters>
        <b-col cols="6">
          <b-button @click="$router.push('/admin/stockproduct/'+stockproduct.id)" class="btn btn-secondary" block title="редактировать">
            <b-icon icon="pencil" aria-hidden="true"></b-icon>
          </b-button>          
        </b-col>
        <b-col cols="6">
          <b-button @click="deleteStockproduct()" class="btn btn-danger" block title="удалить">
            <b-icon icon="trash" aria-hidden="true"></b-icon>
          </b-button>
        </b-col>
      </b-row>                                                 
    </div> 
  </div>
</template>
<script>
import Authentication from "@/components/pages/Authentication";
export default {
  props: ["stockproduct", "admin", 'settings'],
  data() {
    return {
      show_modal:false
    };
  },
  computed:{
    pricefromproduct(){
      if(!this.stockproduct.price){
        return 0
      }
      return  this.stockproduct.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
  },
  methods: {
    download(id, download = false) {
      return Authentication.download(id, download);
    },    
    showModal(show){
      console.log(show)
      this.show_modal = show
    }, 
    async deleteStockproduct(){
       let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/deletestockproduct/"+this.stockproduct.id,
        "post"
      );      
      this.$emit('show-toast', data)
      this.$emit('updateList')
    },
  },
};
</script>
<style lang="scss">
.card-product {
  width: 100%;
  height: 100%;
  display: inline-block;
  padding-bottom: 50px;
  padding-top: 20px;
  &-buttons{
      position: absolute;
      bottom: 0;
      right: 15px;
  }
  .card-name{
      font-size: 22px;
      a{
        color: $color3;
      }
  }
  .card-price{
      position: absolute;
      bottom: 10px;
      font-size: 22px;
      span{
          font-size: 10px;
          display:block;

      }
  }
  .btn,input{
      border-radius: 0;
      margin: 5px 0;
  }
  &-info { 
    position: relative;  
    height: 100%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background-color: #fff;
  }
  .carousel-control-prev-icon {
 background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ccc' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ccc' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}
}
.gray-photo{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #5050508a;
  display: none;
}
.gray-stockproduct{
  .gray-photo{
    display: block;
  }
}
.gray-stockproduct .modal-slider  .gray-photo{
  display: none;
}
</style>