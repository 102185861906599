<template>
  <div class="top-header">
      <b-container class="mt-5">  
        <h2 id="catalog-caption-block">
          <b>
            <span v-if="current_category==-1"> <span v-if="settings" v-html="settings.catalogcaption"></span></span>
            <span v-else>{{ current_name }}</span>
          </b>
        </h2> 
        <b-row>
          <b-col cols="12" md="4" lg="3">
            <div class="sticky-filter">
            <div class="my-3">
                <b-form inline>
                    <b-form-input :placeholder="'цена от '+ valuta" v-model="min" class="w-50 mb-2 px-2"></b-form-input>
                    <b-form-input :placeholder="'цена до '+ valuta" v-model="max" class="w-50 mb-2 px-2"></b-form-input>
                    <b-button class="w-100" style="background-color: #735c68;" @click="updateList()">Найти</b-button>
                </b-form>
              </div>
            <div class="d-none d-md-block d-lg-block bg-white categories">
              
              <b class="p-2 d-block">Производитель</b>
              <div class="categories-block">
                <div v-for="category in [{ value: -1, text: 'Все' }, ...categories]" :key="category.id" class="category p-2 pl-3" :class="{active: current_category==category.value}" @click="changecategory(category.value)">
                {{category.text}}
                </div>
              </div>
              
            </div>
            <div class="d-block d-md-none d-lg-none" id="brand-block">
              <b-form-group label="Бренд" class="mt-3">
                <b-form-select                
                  v-model="current_category"
                  :options="[{ value: -1, text: 'Все' }, ...categories]"
                  @change="updateList()"
                ></b-form-select>
              </b-form-group>
            </div>
            </div>
          </b-col>
          <b-col>
            <b-pagination v-if="settings && total_products && settings.autocount < total_products" 
            align="fill"
            v-model="page"
            :total-rows="total_products"
            :per-page="settings.autocount"
            first-number
            last-number
            :limit="5"
            class="d-flex d-sm-none"
        ></b-pagination>
             <b-row class="catalog-products">
        <b-col
          cols="12"
          md="6"
          lg="4"
          
          v-for="product in products"
          :key="product.id"
        >
          <card-product
          :settings="settings"
            :product="product"
            :admin="false"
          ></card-product>
        </b-col>
      </b-row>
          </b-col>
        </b-row>  
        <b-row>
          <b-col cols="12" md="4" lg="3">
          </b-col>
          <b-col>
        <b-pagination v-if="settings && total_products && settings.autocount < total_products" class="d-none d-sm-flex" v-scroll-to="{ element: '#catalog-caption-block', duration: 500, force:false}"
            align="center"
            v-model="page"
            :total-rows="total_products"
            :per-page="settings.autocount"
            first-number
            last-number
            :limit="10"
        ></b-pagination>
        <b-pagination v-if="settings && total_products && settings.autocount < total_products" v-scroll-to="{ element: '#brand-block', duration: 500, force:false}"
            align="fill"
            v-model="page"
            :total-rows="total_products"
            :per-page="settings.autocount"
            first-number
            last-number
            :limit="5"
            class="d-flex d-sm-none"
        ></b-pagination>
        <mail-form-discount class="my-5"></mail-form-discount>
      </b-col>
      </b-row>

      </b-container>
      
      
  </div>
</template>
<script>
import Authentication from "@/components/pages/Authentication";
import CardProduct from '@/components/components/CardProduct';
import MailFormDiscount from "@/components/components/MailFormDiscount";
export default {
  props:['settings'],
    data() {
    return {
      products:[], 
      current_category: -1,
      categories:[],
      page:1,
      big_text:'Добро пожаловать',
      small_text:'Качественные товары, которыми пользуемся сами',
      price_min: null,
      price_max: null,
      total_products: null
    };
  },
    components:{        
        'card-product':CardProduct,
        'mail-form-discount': MailFormDiscount,
    },
    computed:{
      min: {
       get() {
         return this.price_min
       },
       set(value) {
         this.price_min = value.replace(/\s+/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, " ") 
       }
     },
     max: {
       get() {
         return this.price_max
       },
       set(value) {
         this.price_max = value.replace(/\s+/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, " ") 
       }
     },
      valuta(){
        let val = this.settings? this.settings.valutacatalog.replace(/(<([^>]+)>)/gi, ''):''
        return val?('('+val+')'):''
      },
      current_name(){
        return this.categories.find(category=>category.value==this.current_category).text
      },
    },
  created() {    
    this.getAllcategories();
  },
  watch: {
    page: function () {
      this.getProducts()
    }
  },
  methods: {
    Showmore(){
        this.page++
        this.getProducts()
    },
    async getTotalProducts() {
      let url = "/api/v1/gettotalproducts?public=true&category_id="+this.current_category
      if(this.price_min){
        url+='&min='+this.price_min.replace(/\s+/g, '')
      }
      if(this.price_max){
        url+='&max='+this.price_max.replace(/\s+/g, '')
      }
      let { data } = await Authentication.sendRequestNotAuth(
        this,
        url,
        "get"
      );
      if (data || data == 0) {
        this.total_products = data;
      }
    },
    changecategory(id){
      this.current_category=id
      this.updateList()
    },
    updateList() {
      this.getTotalProducts()
      this.page = 1;
      this.getProducts();
    },
    async getAllcategories() {
      let { data } = await Authentication.sendRequestNotAuth(
        this,
        "/api/v1/categories",
        "get"
      );
      if (data) {
        this.categories = data.map(el=>{ return {value: el.id, text: el.name}});
        this.current_category = this.$route.params.id?this.$route.params.id:-1;
      }
      this.getTotalProducts();
      this.getProducts();
    },
    async getProducts() {     
      let url = "/api/v1/getproductsfront?page="+this.page+"&count="+this.settings.autocount+'&category_id='+this.current_category
      if(this.price_min){
        url+='&min='+this.price_min.replace(/\s+/g, '')
      }
      if(this.price_max){
        url+='&max='+this.price_max.replace(/\s+/g, '')
      }
      let { data } = await Authentication.sendRequestNotAuth(
        this,
        url,
        "get",
      );
      if (data) {      
        this.products=data;
      }
    },  
  },
};
</script>
<style lang="scss">    
.catalog-products{
  .card-product {
    padding-bottom: 20px;
  }
}
    .search-form{
        background-color: $color3;
        color:#fff;
    }
    .price-bottom{
      padding-bottom: 45px;
    }
    .categories{
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }
    .categories-block{
      max-height: calc(100vh - 380px);
      overflow-y: auto;
      margin-bottom: 20px;
    }
    .category{
      border-top: 1px solid #ccc;
      cursor: pointer;
      &:hover, &.active{
        background-color: $color1;
        color: #fff;
        font-weight: 600;
      }
    }
    .custom-select{
      border-radius: 0;
    }
    .categories-block::-webkit-scrollbar {
      width: 7px;
    }

    .categories-block::-webkit-scrollbar-track {
      background-color: #e4e4e4;
    }

    .categories-block::-webkit-scrollbar-thumb {
      background-color: rgba($color1,0.5);
    }
    .page-item.active .page-link {
      background-color: $color2;
      border-color: $color2;      
    }
    .page-link {
      color: $color2;
      &:hover{
        color: $color3;
      }
    }
    .bv-d-xs-down-none {
        display: flex !important;
        span.page-link{
          width:100%;
        }
    }
@media (min-width: 768px) {
    .sticky-filter{
      position:-webkit-sticky; 
      position:sticky;
      top:10px;
    }
    }
</style>