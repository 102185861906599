<template>
  <div>
    <div class="text-center">
      <b-button @click="$bvModal.show('modal-discount-form')" squared variant="success">Информируйте меня о скидках</b-button>
    </div>    
    <loader v-if="load"></loader>
    <b-modal id="mail-send-valid" header-bg-variant="success" centered hide-footer>
      <div class="d-block">
        {{ success_message }}
      </div>
    </b-modal>
    <b-modal id="mail-send-not-valid" header-bg-variant="danger" centered hide-footer>
      <div class="d-block">
        {{ error_message }}
      </div>
    </b-modal>
    <b-modal id="modal-discount-form" hide-footer :title="'Подписаться на информирование о скидках'"
      scrollable centered @hidden="resetParams">
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group class="mt-2" label="Ваше имя">
              <b-input v-model="params.username" placeholder="ФИО"></b-input>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group class="mt-2" label="Номер телефона">
              <b-form-input v-model="params.phone" v-mask="'+7 (###) ###-##-##'" placeholder="+7 (###) ###-##-##"
                id="phone" required :state="validPhone(params.phone)"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group class="mt-2" label="Почта">
              <b-form-input v-model="params.email" placeholder="email"
                id="email" required :state="validMail(params.email)"></b-form-input>
            </b-form-group>
          </b-col>          
          <b-col cols="12" v-if="categories">
            <b-form-group class="mt-2" label="Выбор бренда">
              <b-form-select v-model="params.current_category"
                :options="[{ value: -1, text: 'все электромобили' }, ...categories_option]"
                @change="getProducts()"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" v-if="products">
            <b-form-group class="mt-2" label="модель">
              <b-form-select v-model="params.current_product" :options="[{ value: -1, text: 'все модели' }, ...products]"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

      </b-form>
      <b-button class="mt-2" block @click="addSubscriber()"
        :disabled="!validPhone(params.phone) || !params.username || !validMail(params.email)">Подписаться</b-button>
    </b-modal>
  </div>
</template>

<script>
import Authentication from "@/components/pages/Authentication";
import LoaderSend from "@/components/components/LoaderSend";
export default {
  data() {
    return {
      load: false,
      error_message: "",
      success_message: "",
      categories:[],
      params: {
        current_category: -1,
        current_product: null,
        username: '',
        phone: null,
        email:null
      },
      products: null,
    };
  },
  components: {
    'loader': LoaderSend
  },
  computed:{
    categories_option(){
      return this.categories.map(el => { return { value: el.id, text: el.name } })
    }
  },
  created() {
    this.getAllcategories();
  },
  methods: {
    validPhone(val) {
      return val == null ? null : val.length == 18;
    },
    validMail(val){
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return val == null? null : emailRegex.test(val)
    },
    async getAllcategories() {
      let { data } = await Authentication.sendRequestNotAuth(
        this,
        "/api/v1/categories",
        "get"
      );
      if (data) {
        this.categories = data;        
      }
    },
    async getProducts() {
      this.params.current_product = null
      this.products = null
      if (this.params.current_category != -1) {
        let { data } = await Authentication.sendRequestNotAuth(
          this,
          "/api/v1/getproductsfront?category_id=" + this.params.current_category,
          "get",
        );
        if (data && data.length > 0) {
          this.params.current_product = -1
          this.products = data.map(el => { return { value: el.id, text: el.name } })
        }else{
          this.params.current_product = null
        }
      } 
    },
    async resetParams(){
      this.params = {
        current_category: -1,
        current_product: null,
        username: '',
        phone: null,
        email:null
          }
      this.products=null    
    },
    async addSubscriber() {
      this.load = true;
      if (this.validPhone(this.params.phone) && this.params.username && this.validMail(this.params.email)) {
        let { data } = await Authentication.sendRequestNotAuth(
          this,
          "/api/v1/addSubscriber",
          "post",
          { ...this.params }
        );
        if (data.success) {
          this.success_message = data.message;
          this.$bvModal.show("mail-send-valid");
          this.$bvModal.hide('modal-discount-form')          
        } else {
          this.error_message = data.message;
          this.$bvModal.show("mail-send-not-valid");
        }
      } else {
        this.error_message =
          "Неверно заполнены поля, проверте правильность заполнения";
        this.$bvModal.show("mail-send-not-valid");
      }
      this.load = false;
    },
  },

}
</script>

<style lang="scss"></style>
