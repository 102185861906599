import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import './plugins/v-mask'
import './plugins/vue-animate'
import './plugins/vue-scrollto'
import App from './App.vue'
import router from './router'
import Authentication from '@/components/pages/Authentication'
import VueCookie from 'vue-cookie'

import cookie from './plugins/cookie'
Vue.use(require('vue-moment'));

Vue.use(VueCookie)

Vue.config.productionTip = false
Authentication.checkAuthentication()

new Vue({
  router,
  cookie,
  render: h => h(App)
}).$mount('#app')
