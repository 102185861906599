<template>
  <div class="l-auth-container" v-on:keyup.enter="submitAuthentication">
    <div class="l-auth">
      <b-form v-model="validLogin">
          
        <b-input-group class="mt-2 mb-2">
          <b-input-group-prepend is-text>
            <b-icon icon="person-fill"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            type="text"
            label="Username"
            v-model="credentials.username"
            required
            placeholder="Логин"
          ></b-form-input>
        </b-input-group>
        <b-input-group class="mt-2 mb-2">
          <b-input-group-prepend is-text>              
            <b-icon v-if="loginPasswordVisible" icon="unlock-fill"></b-icon>
            <b-icon v-else icon="lock-fill"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            :type="loginPasswordVisible ? 'text' : 'password'"
            label="Password"
            v-model="credentials.password"
            required
            placeholder="Пароль"
          ></b-form-input>
        </b-input-group>

        <b-button class="align-top" variant="secondary" v-on:click="submitAuthentication">Вход</b-button>
      </b-form>
    </div>   
    <b-toast 
    title="Ошибка входа"
    v-model="toast"
    variant="danger"
    autoHideDelay="5000">
      {{ message }}
    </b-toast>
  </div>
</template>
<script>
import Authentication from "@/components/pages/Authentication";
export default {
  data() {
    return {
      toast: false,
      validLogin: false,
      loginPasswordVisible: false,
      credentials: {
        username: "",
        password: "",
      },
      message: "",
    };
  },
  created(){
    if(Authentication.checkAuthentication()){
      this.$router.push('/admin/users') 
    }
  },
  methods: {
    submitAuthentication() {
      Authentication.authenticate(this, this.credentials, "/admin/users");
    },
  },
};
</script>

<style lang="scss">

.l-auth {
  background-color: $background-color;
  padding: 15px;
  margin: 45px auto;
  min-width: 272px;
  max-width: 320px;
  animation: bounceIn 1s forwards ease;
}

</style>