<template>
<div class="loader-page">
<div class="loader">
  <div class="d-inline-block position-relative">
    <b-iconstack font-scale="3" animation="cylon" class="mr-4">
  <b-icon
        stacked
        icon="square-fill"
        animation="spin-reverse"
      ></b-icon>
    </b-iconstack>
     <div class=" loader-text loader-text-1" >
        E
    </div>
  </div>
<div class="d-inline-block position-relative">  
  <b-iconstack font-scale="5" animation="cylon">
  <b-icon
        stacked
        icon="square-fill"
        animation="spin-reverse"
      ></b-icon>
    </b-iconstack>  
    <div class="loader-text loader-text-2" >
        C
    </div>  
</div>
<div class="d-inline-block position-relative">
    <b-iconstack font-scale="3" animation="cylon"  class="ml-4">
  <b-icon
        stacked
        icon="square-fill"
        animation="spin-reverse"
      ></b-icon>
    </b-iconstack>
    <div class="loader-text loader-text-3" >
        O
    </div>
</div>
</div>
</div>
    
</template>

<script>
export default {
  
};
</script>

<style lang="scss">
@keyframes movetext {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(25%);
  }
}
    .loader-page{
        background-color: $color1;
        position: fixed;
        width: 100vw;
        height: 100vh;
        top:0;
        left:0;
        color:rgb(21 137 64);
        z-index: 5000;  
        display: flex;
        align-items: center;
        justify-content: center;       
        .loader-text{
          width:48px;
          height: 48px;
          color:#ffffff;
          font-weight: 600;
          transform-origin: center;
    -webkit-animation: 0.75s infinite ease-in-out alternate b-icon-animation-cylon;
    animation: 0.75s infinite ease-in-out alternate b-icon-animation-cylon;
          position: absolute;
          z-index: 1000;
          left:0;
          top:6px;
          text-align: center;
          font-size: 36px;
          line-height: 1;
        }        
        .loader-text-1{
        }  
        .loader-text-2{
          top:13px;
          font-size: 54px;
           width:80px;
          height: 80px;
        }  
        .loader-text-3{
          left: 25px;
        }    
    }

</style>