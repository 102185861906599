<template>
    <div class="top-header">
        
        <b-container class="mt-5 mb-3">
            <div class="bg-white p-4 p-md-5 shadow">
                <page-show :alias="'contract'"></page-show>
            </div>

        </b-container>
    
        <div class="">
            <b-container>
                <h2 class="py-5 m-0">Вопросы и ответы</h2>
                <b-row class="mb-5" >
                    <b-col v-for="faq in faqs" :key="faq.id" md="6" class="mb-3">
                        <div class="border p-3 bg-white shadow">
                            <div class="bg-white position-relative" v-b-toggle="'collapse-' + faq.id" >
                                        <div class="d-inline-block align-middle pr-4"><b>{{ faq.caption }}</b></div>

                                        <span class="when-opened text-danger" style="font-size: 20px;">
                                            <b-icon icon="dash-circle-fill" aria-hidden="true"></b-icon>
                                        </span>
                                        <span class="when-closed text-success" style="font-size: 20px;">
                                            <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
                                        </span>
                             
                                
                            </div>
                            <b-collapse :id="'collapse-' + faq.id" accordion="my-accordion">
                                <hr>
                                <div class="ql-editor  ql-viewer" v-html="faq.text"></div>
                            </b-collapse>
                        </div>
                    </b-col>
                </b-row>

            </b-container>
        </div>
    </div>
</template>

<script>
import Authentication from "@/components/pages/Authentication";
import PageShow from "@/components/components/PageShow";
export default {
    data() {
        return {
            faqs: null,
        };
    },
    components: {
        'page-show': PageShow,
    },
    created() {
        this.getfaqs();
    },
    methods: {
        async getfaqs() {
            let { data } = await Authentication.sendRequestNotAuth(
                this,
                "/api/v1/faqs",
                "get",
            );
            if (data) {
                this.faqs = data;
            }
        },
    }
}
</script>

<style lang="scss"></style>
