<template>
<div v-if="settings" class="text-nowrap">
    <a class="d-inline-block social" v-if="settings.whatsapp" :href="settings.whatsapp" title="Написать в WhatsApp" target="_blank"><img src="~@/assets/images/whatsapp.png"></a>    
    <a class="d-inline-block social" v-if="settings.phone" :href="'tel:'+settings.phone" :title="settings.phone" target="_blank"><img src="~@/assets/images/call.png" ></a> 
    <a class="d-inline-block social" v-if="settings.telegram" :href="settings.telegram" title="Написать в Telegram-канал" target="_blank"><img src="~@/assets/images/telegram.png" ></a>
    <!--<a class="d-inline-block social" href="/products" title="Написать на электронную почту"><img src="~@/assets/images/mail.png"></a> --> 
</div>  
</template>

<script>
export default {
    props:['settings']
};
</script>

<style lang="scss">
</style>