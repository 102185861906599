<template>
  <div class="page-admin-block">
    <b-container class="py-4">
      <h3>Каталог</h3>
      <hr />
      <div class="ml-3">
        <h5 class="mt-1">Добавить в католог</h5>
        <b-form>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Название" class="mt-1">
                <b-form-input
                  id="product_name"
                  type="text"
                  label="Name"
                  v-model="newproduct.name"
                  required
                  placeholder="Название"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Бренд" class="mt-1" id="brand-block">
                <b-form-select
                  v-model="newproduct.category_id"
                  :options="categories"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-button
            class="my-2"
            variant="secondary"
            @click="addProduct()"
            title="Добавить товар"
          >
            Добавить в каталог
          </b-button>
        </b-form>
      </div>
      <hr />
      <div class="ml-3">
      <h5 id="catalog-caption-block">Каталог</h5>
      <b-form-group label="Бренд" class="mt-3">
        <b-form-select
          v-model="current_category"
          :options="[{ value: -1, text: 'все' }, ...categories]"
          @change="updateList()"
        ></b-form-select>
      </b-form-group>
      <b-pagination v-if="settings && total_products && settings.autocount < total_products" class="d-none d-sm-flex"
            align="center"
            v-model="page"
            :total-rows="total_products"
            :per-page="settings.autocount"
            first-number
            last-number
            :limit="10"
        ></b-pagination>
        <b-pagination v-if="settings && total_products && settings.autocount < total_products"
            align="fill"
            v-model="page"
            :total-rows="total_products"
            :per-page="settings.autocount"
            first-number
            last-number
            :limit="5"
            class="d-flex d-sm-none"
        ></b-pagination>
      <b-row align-h="center" class="catalog-admin">
        <b-col
          cols="12"
          md="6"
          lg="4"
          class="mb-4"
          v-for="product in products"
          :key="product.id"
        >
          <card-product
            :settings="settings"
            v-on:updateList="updateList"
            v-on:show-toast="showToast"
            :product="product"
            :admin="true"
          ></card-product>
        </b-col>
      </b-row>
      <b-pagination v-if="settings && total_products && settings.autocount < total_products" class="d-none d-sm-flex" v-scroll-to="{ element: '#catalog-caption-block', duration: 500, force:false}"
            align="center"
            v-model="page"
            :total-rows="total_products"
            :per-page="settings.autocount"
            first-number
            last-number
            :limit="10"
        ></b-pagination>
        <b-pagination v-if="settings && total_products && settings.autocount < total_products" v-scroll-to="{ element: '#brand-block', duration: 500, force:false}"
            align="fill"
            v-model="page"
            :total-rows="total_products"
            :per-page="settings.autocount"
            first-number
            last-number
            :limit="5"
            class="d-flex d-sm-none"
        ></b-pagination>
      </div>
    </b-container>
    <b-toast
      :title="toast.title"
      v-model="toast.show"
      :variant="toast.variant"
      autoHideDelay="5000"
      >{{ toast.message }}</b-toast
    >
  </div>
</template>
<script>
import Authentication from "@/components/pages/Authentication";
import CardProduct from "@/components/components/CardProduct";
export default {
  props:['settings'],
  data() {
    return {
      current_category: -1,
      products: [],
      categories: [],
      page: 1,
      total_products: null,
      toast: {
        show: false,
        title: "",
        variant: "",
        message: "",
      },
      newproduct: {
        name: "",
        category_id: null,
      },
    };
  },
  components: {
    "card-product": CardProduct,
  },
  watch: {
    page: function () {
      this.getAllProducts()
    }
  },
  created() {
    this.getAllcategories();
    this.getTotalProducts();
    this.getAllProducts();
  },
  methods: {
    showToast(data) {
      if (data) {
        if (data.success) {
          this.toast = {
            show: true,
            title: "Выполнено",
            variant: "success",
            message: data.message,
          };
        } else {
          this.toast = {
            show: true,
            title: "Ошибка",
            variant: "danger",
            message: data.message,
          };
        }
      }
    },
    async getTotalProducts() {
      let { data } = await Authentication.sendRequestNotAuth(
        this,
        "/api/v1/gettotalproducts?category_id="+this.current_category,
        "get"
      );
      if (data || data == 0) {
        this.total_products = data;
      }
    },
    Showmore() {
      this.page++;
      this.getAllProducts();
    },
    updateList() {
      this.getTotalProducts()
      this.page = 1;
      this.getProducts();
    },
    async getAllcategories() {
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/categories",
        "get"
      );
      if (data) {
        this.categories = data.map((el) => {
          return { value: el.id, text: el.name };
        });
      }
    },
    async getAllProducts() {
      let { data } = await Authentication.sendRequestNotAuth(
        this,
        "/api/v1/products?page=" +
          this.page +
          "&count=" +
          this.settings.autocount +
          "&category_id=" +
          this.current_category,
        "get"
      );
      if (data) {
        this.products=data;
      }
    },
    async addProduct() {
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/addproduct",
        "post",
        this.newproduct
      );
      this.showToast(data);
      if (data.success) {
        this.$router.push("/admin/product/" + data.product.id).catch(() => {});
      }
    },
  },
};
</script>
<style lang="scss">
/*.cards-product{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}*/
</style>