<template>
  <div>      
    <b-container class="py-4"> 
    <editor v-if="page.text!=null" v-model="page.text"></editor>        
      <b-button class="mt-1" @click="changePage()">Сохранить</b-button>
    </b-container>
    <b-toast
      :title="toast.title"
      v-model="toast.show"
      :variant="toast.variant"
      autoHideDelay="5000"
    >{{ toast.message }}</b-toast>
  </div>
</template>
<script>
import Authentication from "@/components/pages/Authentication";
import Editor from "@/components/components/Editor.vue";


export default {
  data() {
    return {
      toast: {
        show: false,
        title: "",
        variant: "",
        message: "",
      },
      page: {
          text:null
      },
    }
  },      
  props:['alias'],
  components: {
    Editor
  },
  created() {
    this.getPage();
  },
  methods: {   
    showToast(data) {
      if (data) {
        if (data.success) {
          this.toast = {
            show: true,
            title: "Выполнено",
            variant: "success",
            message: data.message,
          };
        } else {
          this.toast = {
            show: true,
            title: "Ошибка",
            variant: "danger",
            message: data.message,
          };
        }
      }
    },
    async getPage() {        
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/pageadmin",
        "post",
        { alias: this.alias }
      );
      if (data) {
        this.page = data;
      }
    },
    async changePage() {
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/changepage",
        "post",
        this.page
      );
      this.showToast(data);
    },   
  },
};
</script>
<style lang="scss">
.html-view{
    border: 1px solid #ccc;
}

.ql-container{
    font-size: 16px;
}
.col4 {
    display: inline-block;
    width:calc(100% / 4);
    vertical-align: top;
    margin-bottom: 15px;
}

.col5 {
    display: inline-block;
    width:calc(100% / 5);
    vertical-align: top;
    margin-bottom: 15px;
}

.col3 {
    display: inline-block;
    width:calc(100% / 3);
    vertical-align: top;
    margin-bottom: 15px;
}

.col2 {
    display: inline-block;
    width:calc(100% / 2);
    vertical-align: top;
    margin-bottom: 15px;
}

.ql-col5:after {
  content: "\2630 5";
}

.ql-col4:after {
  content: "\2630 4";
}
.ql-col3:after {
  content: "\2630 3";
}
.ql-col2:after {
  content: "\2630 2";
}


@media (max-width:768px){
    .col2 {
        width:100%;
    }
    .col3 {
        width:calc(100% / 2);
    }
    .col4 {
        width:calc(100% / 2);
    }
    .col5 {
        width:calc(100% / 3);
    }
}
@media (max-width:420px){
    .col3 {
        width:100%;
    }
    .col4 {
        width:100%;
    }
    .col5 {
        width:calc(100% / 2);
    }
}

.ql-br:after {
  content: "\21B5";
}


.ql-snow{
.ql-picker{
    &.ql-size{
        .ql-picker-label,
        .ql-picker-item{
            &::before{
                content: attr(data-value) !important;
            }
        }
    }
}
}

.ql-editor{
    padding:0
}

.page-admin-block{
    padding-top: 30px;
  .ql-editor{
    padding:10px
}
}

.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 1em;
}
.ql-editor .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 2em;
}
.ql-editor .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 3em;
}
.ql-editor .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 4em;
}
.ql-editor .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 5em;
}
.ql-editor .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 6em;
}
.ql-editor .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 7em;
}
.ql-editor .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 8em;
}
.ql-editor .ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 9em;
}
.ql-editor .ql-indent-10:not(.ql-direction-rtl) {
    padding-left: 10em;
}
.ql-editor .ql-indent-11:not(.ql-direction-rtl) {
    padding-left: 11em;
}
.ql-editor .ql-indent-12:not(.ql-direction-rtl) {
    padding-left: 12em;
}
.ql-editor .ql-indent-13:not(.ql-direction-rtl) {
    padding-left: 13em;
}
.ql-editor .ql-indent-14:not(.ql-direction-rtl) {
    padding-left: 14em;
}
.ql-editor .ql-indent-15:not(.ql-direction-rtl) {
    padding-left: 15em;
}

.ql-editor .ql-video{
    display: inline-block;
    margin-right: 10px;
}
.custom-file-input{
    cursor: pointer;
}
.custom-file-label{
    margin: 0;
    border-radius:0 ;
    cursor: pointer;
    content: '';
}

.custom-file-label::after{
    content:'выбрать файл' !important;    
}
.remove-file{
    color: #fff;
    background-color: red;
    cursor: pointer;
}

.doc{
    border-top: 1px solid #ccc;
    border-right: 1px solid #ccc;
    &-name{
        display: inline-block;
        margin-left: 10px;
    }
}

.doc-list .doc:last-child{
    border-bottom: 1px solid #ccc;
}

.page-admin-block .quill-editor iframe {
    pointer-events: none;
}


</style>