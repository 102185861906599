<template>
  <div class="page-admin-block">
    <b-container class="py-4">
      <b-button @click="$router.push('/admin/stockproducts')" variant="outline-secondary" class="mb-3">
        В наличии
      </b-button>
      <h3  v-if="stockproduct">Редактировать {{stockproduct.name}}</h3>
      <hr>
      <b-form v-if="stockproduct" class="ml-3">
        <b-row>
          <b-col cols="12" md="7">
            <b-form-group label="Название">
          <b-form-input
            id="stockproduct_name"
            type="text"
            label="Name"
            v-model="stockproduct.name"
            required
            placeholder="Название"
          ></b-form-input>
        </b-form-group>
          </b-col>   
          <b-col cols="12" md="7">
            <b-form-group label="Цена">
          <b-form-input
            id="stockproduct_price"
            type="text"
            label="Price"
            v-model="stockproduct.price"
            placeholder="Цена"
          ></b-form-input>
        </b-form-group>
          </b-col>          
        </b-row>               
        <b-form-group label="Описание">
          <editor v-model="stockproduct.description"></editor>
        </b-form-group>        
        <b-form-group label="Описание на главной странице">
          <editor v-model="stockproduct.description_main_page"></editor>                    
        </b-form-group>
        <b-input-group>
          <b-form-file
            placeholder="Изображения"
            id="newimages"
            multiple
            ref="files"
            @change="handleFileUpload"
          ></b-form-file>
        </b-input-group >
        
        <div>
          <div
            v-for="image in stockproduct.images"
            class="edit-image"
            :key="image.id"
          >
             <button @click="deleteImage(image.id)" class="btn btn-danger" title="удалить"><b-icon icon="X" aria-hidden="true"></b-icon></button> 
            <img :src="download(image.id)" />
            <button @click="setMain(image.id)" class="btn btn-secondary" type="button" v-if="!image.main">Сделать главной</button>
          </div>
        </div>
        <b-form-checkbox v-model="stockproduct.main_page">
          Разместить на главной странице
        </b-form-checkbox>
        <b-form-checkbox v-model="stockproduct.public">
          Опубликовать
        </b-form-checkbox>    
            

        <b-button
          class="my-3"
          variant="secondary"
          @click="changeStockproduct()"
          title="Сохранить"
        >
          Сохранить
        </b-button>
      </b-form>
    </b-container>
    <b-toast
      :title="toast.title"
      v-model="toast.show"
      :variant="toast.variant"
      autoHideDelay="5000"
      >{{ toast.message }}</b-toast
    >
  </div>
</template>
<script>
import Authentication from "@/components/pages/Authentication";
import Editor from "@/components/components/Editor.vue";
export default {
  data() {
    return {
      stockproduct: null,
      new_images:"",
      toast: {
        show: false,
        title: "",
        variant: "",
        message: "",
      },
    };
  },
  created() {
    this.getStockproduct();
  },
  components:{
    'editor':Editor,
  },
  methods: {
    showToast(data) {
      if (data) {
        if (data.success) {
          this.getStockproduct();
          this.toast = {
            show: true,
            title: "Выполнено",
            variant: "success",
            message: data.message,
          };
        } else {
          this.toast = {
            show: true,
            title: "Ошибка",
            variant: "danger",
            message: data.message,
          };
        }
      }
    },
    download(id, download = false) {
      return Authentication.download(id, download);
    },   
    async getStockproduct() {
      let { data } = await Authentication.sendRequestNotAuth(
        this,
        "/api/v1/stockproduct/"+this.$route.params.id,
        "get"
      );
      if (data) {
        this.stockproduct = data;
      }
    },
    async changeStockproduct() {
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/changestockproduct",
        "post",
        {...this.stockproduct,new_images: this.new_images,}
      );
      this.showToast(data);
      this.$refs['files'].reset()
    },
    handleFileUpload(event) {
      if (event.target.files.length > 0) {
        this.new_images = event.target.files;
      } else {
        this.new_images = "";
      }
    },
    async setMain(id){
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/changemainimage",
        "post",
        {id: id, stockproduct_id: this.stockproduct.id}
      );
      this.showToast(data);
    },
    deleteImage(id){
        let images = this.stockproduct.images;
        this.stockproduct.images=images.filter(el=>el.id!=id)
    },
  },
};
</script>
<style lang="scss">
</style>