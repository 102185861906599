<template>
  <div class="page-admin-block">
    <loader v-if="load"></loader>
    <b-container class="pt-4">      
      <h3>Рассылка подписчикам</h3>
   
      <hr />
      <b-row no-gutters>      
      <b-col cols="12">
        <div class="ml-3">
          <b-row>
          <b-col>
            <b-form-group label="Бренд" class="mt-2">
        <b-form-select
          v-model="current_category"
          :options="[{ value: -1, text: 'все' },{ value: null, text: 'не выбран конкретный бренд' }, ...categories]"
          @change="getProducts()"
        ></b-form-select>
      </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Модель" class="mt-2">
        <b-form-select
          v-model="current_product"
          :options="[{ value: -1, text: 'все' },{ value: null, text: 'не выбрана конкретная модель' }, ...products]"
          @change="updateList()"
        ></b-form-select>
      </b-form-group>
          </b-col>
        </b-row>         
      
      <h5>Подписчики</h5>      
        <b-table striped hover :items="subscribers" :fields="fields" sticky-header style="max-height: 500px;"  ref="table-subscribers">
          <template #head(check_subscriber)>
            <b-form-checkbox v-model="check_all_subscribers" @change="checkAllSubscribers()">
              Все
            </b-form-checkbox>
          </template>
          <template #cell(check_subscriber)="data">
            <b-form-checkbox v-model="data.item.check_subscriber">
            </b-form-checkbox>
          </template>
          <template #cell(buttons)="data">
            <b-button @click="deleteSub(data.item.id)" size="sm" title="Удалить подписчика">
              <b-icon icon="trash"></b-icon>
            </b-button>
          </template>
        </b-table>
        <b-overlay :show="isBusy" no-wrap opacity="0.5"></b-overlay>
        </div>
                  
    </b-col>
    <b-col>     
      <div class="ml-3">          
      <b-form-group label="Тема рассылки"  class="mt-2">
          <b-input v-model="email_theme"></b-input>
      </b-form-group>
      <b-form-group label="Текст рассылки"  class="mt-2">
          <editor v-model="email_message"></editor>
      </b-form-group>
      <b-button @click="sendMassEmail()" v-if="email_message && email_theme">
        Отправить
      </b-button>
      </div>
    </b-col>
    </b-row>
    <div v-if="result_mass_email_send.length>0" class="mt-3 ml-3">
      <b-table striped hover :items="result_mass_email_send" :fields="fields_result">
        <template #cell(result)="data">
            <span class="text-success" v-if="data.item.result">Отправлено</span>
            <span class="text-danger" v-else>Ошибка при отправке</span>
          </template>
      </b-table>
    </div>
    
    
  </b-container>
    <b-toast
      :title="toast.title"
      v-model="toast.show"
      :variant="toast.variant"
      autoHideDelay="5000"
      >{{ toast.message }}</b-toast
    >
  </div>
</template>
<script>

import Authentication from "@/components/pages/Authentication";
import Editor from "@/components/components/Editor.vue";
import LoaderSend from "@/components/components/LoaderSend";
export default {
  props:['settings'],
  data() {
    return {
      result_mass_email_send:[],
      check_all_subscribers:false,
      isBusy: false,
      current_category: -1,
      current_product:-1,
      products: [],
      categories: [],
      subscribers:[],
      page: 1,
      count: 10,
      email_message:'',
      email_theme:'',
      total_subscribers:0,
      fields_result:[
        {
          key:'email',
          label: 'Адресат',
        },
        {
          key:'result',
          label: 'Результат отправки',
        },

      ],
      fields:[
        {
          key: 'check_subscriber',
          label:''
        },
        {
          key:'username',
          label: 'ФИО',
        },
        {
          key:'phone',
          label: 'Телефон',
        },
        {
          key:'email',
          label: 'Почта',
        },
        {
          key:'category.name',
          label: 'Бренд',
        },
        {
          key:'product.name',
          label: 'Модель',
        },
        {
          key: 'buttons',
          label: ''
        }
      ],
      toast: {
        show: false,
        title: "",
        variant: "",
        message: "",
      },
      load:false,
    };
  },
  components:{
        'editor': Editor,
        'loader': LoaderSend
    },
  watch: {
    isBusy(newVal, oldVal) {
      if (newVal !== oldVal) {
        const tableScrollBody = this.$refs["table-subscribers"].$el;
        if (newVal === true) {
          tableScrollBody.classList.add("overflow-hidden");
        } else {
          tableScrollBody.classList.remove("overflow-hidden");
        }
      }
    }
  },
  created() {
    this.getAllcategories();
    this.updateList();
  },
  mounted() {
    const tableScrollBody = this.$refs["table-subscribers"].$el;
    tableScrollBody.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    const tableScrollBody = this.$refs["table-subscribers"].$el;
    tableScrollBody.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    checkAllSubscribers(){
      this.subscribers = this.subscribers.map(sub=>({...sub, check_subscriber: this.check_all_subscribers}))      
    },
    async getTotalSubscribers() {
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/gettotalsubscribers?category_id=" +
          this.current_category +
          "&product_id=" +
          this.current_product,
        "get"
      );
      if (data || data == 0) {
        this.total_subscribers = data;
      }
    },
    showToast(data) {
      if (data) {
        if (data.success) {
          this.toast = {
            show: true,
            title: "Выполнено",
            variant: "success",
            message: data.message,
          };
        } else {
          this.toast = {
            show: true,
            title: "Ошибка",
            variant: "danger",
            message: data.message,
          };
        }
      }
    },
    async updateList() {
      this.getTotalSubscribers()
      this.page = 1;
      this.subscribers = await this.getSubscribers();
    },
    async getSubscribers(){
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/subscribers?page=" +
          this.page +
          "&count=" +
          this.count +
          "&category_id=" +
          this.current_category +
          "&product_id=" +
          this.current_product,
        "get"
      );
      if (data) {
        return data.map(sub=>({...sub, check_subscriber: this.check_all_subscribers})) 
      }else{
        return []
      }
    },
    async getAllcategories() {
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/categories",
        "get"
      );
      if (data) {
        this.categories = data.map((el) => {
          return { value: el.id, text: el.name };
        });
      }
      this.getTotalSubscribers()
    },
    async getProducts() {
      if (this.current_category != -1) {
        let { data } = await Authentication.sendRequest(
          this,
          "/api/v1/products?category_id=" + this.current_category,
          "get",
        );
        if (data && data.length > 0) {
          this.products = data.map(el => { return { value: el.id, text: el.name } })          
        }
      } else {
        this.current_product = -1
        this.products = []
      }
      this.updateList()
    },
    async deleteSub(id){
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/deletesubscriber",
        "post",
        { id: id}
      );
      this.showToast(data);
      if(data.success){
        this.subscribers =  this.subscribers.filter(el=> el.id!=id)
        this.total_subscribers--
      }
    },
    async sendMassEmail(){
      this.load=true
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/sendmassemail",
        "post",
        { category_id: this.current_category,
          product_id: this.current_product,
          email_message:this.email_message,
          email_theme:this.email_theme,
          check_all: this.check_all_subscribers,
          exclude: this.subscribers.filter(sub=> this.check_all_subscribers? !sub.check_subscriber: sub.check_subscriber).map(sub=>sub.id)
        }
      );
      this.showToast(data);
      if(data.success){
        this.result_mass_email_send = data.info
      }
      this.load=false
    },
    async fetchItems() {
      if (this.subscribers.length === this.total_subscribers) return;
      this.isBusy = true;
      this.page++;
      const newItems = await this.getSubscribers();
      this.subscribers = this.subscribers.concat(newItems);
      this.isBusy = false;
    },
    onScroll(event) {
      if (
        event.target.scrollTop + event.target.clientHeight >=
        event.target.scrollHeight
      ) {
        if (!this.isBusy) {
          this.fetchItems();
        }
      }
    }
  },
};
</script>
<style lang="scss">
/*.cards-product{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}*/
</style>