<template>
<div> 
  <div id="map" style="width:100%; height: 500px;"></div>
</div>
</template>

<script>
import DG from '2gis-maps'
export default {
   props:['link'],
    data() {
    return {
      coords:[]
    };
  },
  mounted() {
    this.coords = this.link.split(/\?m=/)[1].split(/%2C|%2F/)
    var self = this
    DG.then(function () {
            var map,
                myIcon;

            map = DG.map('map', {
                center: [self.coords[1], self.coords[0]],
                zoom: self.coords[2],
                scrollWheelZoom:false,
                touchZoom:false,
                dragging:false,
                poi:false,
                closePopupOnClick:false,
                zoomControl:false,
                fullscreenControl:false,
            });

            myIcon = DG.icon({
                iconUrl: '/img/logo.png',
                iconSize: [35, 35]
            });
            DG.popup()
              .setLatLng( [self.coords[1], self.coords[0]])
              .setContent('<div style="padding:10px; text-align:center;"><a href="'+self.link+'" style="color:#fff" target="_blank">Мы находимся здесь</a></div>')
              .openOn(map)

            var marker = DG.marker( [self.coords[1], self.coords[0]], {
                icon: myIcon,
                title:'Открыть в 2gis'
            }).addTo(map)

            marker.on('click', function () {
              window.open(self.link, '_blank');
            });
           
        });
  },

}
</script>

<style lang="scss">
</style>
