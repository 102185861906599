<template>
  <div class="page-admin-block">
    <b-container> 
      <h3>О нас</h3>
      <hr>
      <page-editor :alias="'about'"></page-editor>
    </b-container>
  </div>
</template>
<script>
import PageEditor from "@/components/components/PageEditor";

export default {
  components: {
    'page-editor': PageEditor
  },  
};
</script>
<style lang="scss">
</style>