<template>
  <div class="page-admin-block">
    <b-container class="py-4">
      <h3>Пользователи</h3>   
       <hr>
       <div class="ml-3">       
      <h5 class="mt-2">Добавить пользователя</h5>
      <b-form inline>
        <b-input-group>
          <b-input-group-prepend is-text>
            <b-icon icon="person-fill"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            type="text"
            label="Username"
            v-model="newuser.username"
            required
            placeholder="Логин"
          ></b-form-input>
        </b-input-group>
        <b-input-group class="mt-2 mb-2">
          <b-input-group-prepend is-text>
            <b-icon icon="lock-fill"></b-icon>
          </b-input-group-prepend>
          <b-form-input label="Password" v-model="newuser.password" type="password" required placeholder="Пароль"></b-form-input>
        </b-input-group>
        <b-button variant="secondary" @click="addUser()" title="Добавить пользователя">
          <b-icon icon="plus"></b-icon>
        </b-button>
      </b-form>
      </div>
      <hr>
      <div class="ml-3">
      <h5>Список пользователей</h5>   
      <div v-for="user in users" :key="user.id">
        <b-row align-v="center" >
          <b-col cols="3" md="2">{{ user.username }}</b-col>
          <b-col cols="9" md="6" lg="4">
            <b-input-group class="mt-2 mb-2">
              <b-input-group-prepend is-text>
                <b-icon icon="lock-fill"></b-icon>
              </b-input-group-prepend>
              <b-form-input
                type="text"
                label="Password"
                v-model="user.newpassword"
                required
                placeholder="Пароль"
              ></b-form-input>
              <b-input-group-append>
                <b-button @click="changePassword(user)" title="Изменить пароль">
                  <b-icon icon="pencil"></b-icon>
                </b-button>
                <b-button @click="deleteUser(user)" title="Удалить пользователя">
                  <b-icon icon="trash"></b-icon>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </div>
      </div>
      <hr>
    </b-container>
    <b-toast
      :title="toast.title"
      v-model="toast.show"
      :variant="toast.variant"
      autoHideDelay="5000"
    >{{ toast.message }}</b-toast>
  </div>
</template>
<script>
import Authentication from "@/components/pages/Authentication";
export default {
  data() {
    return {
      users: [],
      toast: {
        show: false,
        title: "",
        variant: "",
        message: "",
      },
      newuser: {
        password: "",
        username: "",
      },
    };
  },
  created() {
    this.getAllUsers();
  },
  methods: {
    showToast(data) {
      if (data) {
        if (data.success) {
          this.getAllUsers();
          this.toast = {
            show: true,
            title: "Выполнено",
            variant: "success",
            message: data.message,
          };
        } else {
          this.toast = {
            show: true,
            title: "Ошибка",
            variant: "danger",
            message: data.message,
          };
        }
      }
    },
    async getAllUsers() {
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/users",
        "get"
      );
      if (data) {
        this.users = data;
      }
    },
    async addUser() {
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/adduser",
        "post",
        this.newuser
      );
      this.showToast(data);

      this.newuser = {
        password: "",
        username: "",
      };
    },
    async deleteUser(user) {
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/deleteUser",
        "post",
        { username: user.username }
      );
      this.showToast(data);
    },
    async changePassword(user) {
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/changepass",
        "post",
        { password: user.newpassword, username: user.username }
      );
      this.showToast(data);
    },
  },
};
</script>
<style lang="scss">
</style>