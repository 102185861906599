<template>
  <div class="page-admin-block">
    <b-container class="py-4">
      <h4>Настройки</h4>   
      <div v-for="setting in settings" :key="setting.id">
        <b-row align-v="center">
          <b-col cols="12" md="3">{{ setting.name }}</b-col>
          <b-col cols="12" md="6">
            <div v-if="['footer','mainblock','valutacatalog','valutastock'].indexOf(setting.key)!=-1">
                <editor v-model="setting.value"></editor>
                <b-button @click="changeSetting(setting)" title="Изменить настройку">
                  Сохранить <b-icon icon="pencil"></b-icon>
                </b-button>
            </div>
            <div v-else>
              <b-input-group class="mt-2 mb-2">
              <b-form-input
                type="text"
                label="Setting"
                v-model="setting.value"
                required
                placeholder="Значение"
              ></b-form-input>
              <b-input-group-append>
                <b-button @click="changeSetting(setting)" title="Изменить настройку">
                  <b-icon icon="pencil"></b-icon>
                </b-button>
              </b-input-group-append>
            </b-input-group>
            </div>            
          </b-col>
        </b-row>
      </div>
    </b-container>
    <b-toast
      :title="toast.title"
      v-model="toast.show"
      :variant="toast.variant"
      autoHideDelay="5000"
    >{{ toast.message }}</b-toast>
  </div>
</template>
<script>
import Authentication from "@/components/pages/Authentication";
import Editor from "@/components/components/Editor.vue";
export default {
  data() {
    return {
      settings: [],
      toast: {
        show: false,
        title: "",
        variant: "",
        message: "",
      },
    };
  },
  components:{
        'editor': Editor
  },
  created() {
    this.getAllSettings();
  },
  methods: {
    showToast(data) {
      if (data) {
        if (data.success) {
          this.toast = {
            show: true,
            title: "Выполнено",
            variant: "success",
            message: data.message,
          };
        } else {
          this.toast = {
            show: true,
            title: "Ошибка",
            variant: "danger",
            message: data.message,
          };
        }
      }
    },
    async getAllSettings() {
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/settings",
        "get"
      );
      if (data) {
        this.settings = data;
      }
    },
    async changeSetting(setting) {
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/changesetting",
        "post",
        setting
      );
      this.showToast(data);
    },
  },
};
</script>
<style lang="scss">
</style>