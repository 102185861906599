<template>
  <div class="top-header" v-if="settingscontacts">
    <div class="bg-white py-5" v-if="settingscontacts[2]">
      <b-container>
        <div v-if="settingscontacts[1]" class="my-2 ql-editor  ql-viewer" v-html="settingscontacts[1].text"></div>
        <h2 class="text-center mb-5" v-if="settings.managercaption"><b><span v-html="settings.managercaption"></span></b></h2>
        <b-row class="justify-content-center">
          <b-col v-for="contact in settingscontacts[2]" :key="contact.id" cols="6" lg="3" md="4" class="mb-3 px-1">
            <card-contact-client :contact="contact" :settings="settings" v-on:set-manager="setManager"></card-contact-client>
          </b-col>
        </b-row>
        <mail-form-feedback :manager="manager" :settings="settings" :categories="categories" v-on:reset-manager="resetManager"></mail-form-feedback>
      </b-container>
    </div>
  </div>
</template>

<script>
import Authentication from "@/components/pages/Authentication";
import CardContactClient from "@/components/components/CardContactClient";
import MailFormFeedback from "@/components/components/MailFormFeedback";
export default {
  props:['settings'],
  data() {
    return {
      settingscontacts: null,    
      manager:null,
      categories: null,
    };
  },
  components: {
    'card-contact-client': CardContactClient,
    'mail-form-feedback': MailFormFeedback
  },
  mounted() {
    this.getSettings();
    this.getAllcategories()
  },
  methods: {  
    async getAllcategories() {
      let { data } = await Authentication.sendRequestNotAuth(
        this,
        "/api/v1/categories",
        "get"
      );
      if (data) {
        this.categories = data.map(el => { return { value: el.id, text: el.name } });
      }
    },
    async setManager(contact){
      this.manager = contact
      this.$bvModal.show('modal-feedback-form')
    }, 
    async resetManager(){
      this.manager=null
    },
    async getSettings() {
      let { data } = await Authentication.sendRequestNotAuth(
        this,
        "/api/v1/getcontactsssettings",
        "get",
      );
      if (data) {
        this.settingscontacts = data;
      }
    },
  },

}
</script>

<style lang="scss"></style>
