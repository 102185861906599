<template>
    <div class="border bg-white position-relative" style="height: 100%;">      
        <div class="p-2">
            <b-input-group class="mt-2 mb-2">
            <b-form-input
                  type="text"
                  label="Name"
                  v-model="category.name"
                  required
                  placeholder="Название"
                ></b-form-input>
              </b-input-group>
              <b-input-group class="mt-2 mb-2">
            <b-form-file 
              placeholder="Логотип"
              ref="filesedit"
              @change="handleFileUpload"
              ></b-form-file>
              </b-input-group>
        </div>  
        <div class="d-flex align-items-center" style="height: calc(100% - 116px);">
          <img :src="(imagesrc ? imagesrc:download(category.logo.id))" width="100%" style="margin-bottom: 45px; ;"/>
        </div>
        
              
              <div class="position-absolute" style="bottom: 0; width: 100%;">
                <b-row no-gutters>
                <b-col cols="6">
                    <b-button @click="changecategory(category)" variant="secondary" block title="Сохранить изменения">
                    <b-icon icon="pencil"></b-icon>
                  </b-button>
                </b-col>
                <b-col  cols="6">
                    <b-button variant="danger" @click="deletecategory(category)" block title="Удалить бренд">
                    <b-icon icon="trash"></b-icon>
              </b-button>
                </b-col>
              </b-row>
              </div>             
              
                  
            </div>      
  </template>
  <script>
  import Authentication from "@/components/pages/Authentication";
  export default {
    props:['category'],
    data() {
      return {
          new_images:"",
          imagesrc: null,
      };
    },
    methods: {
      download(id, download = false) {
        return Authentication.download(id, download);
      },
      async deletecategory(category) {
        let { data } = await Authentication.sendRequest(
          this,
          "/api/v1/deletecategory/"+category.id,
          "post",
        );
        this.$emit('show-toast', data)
      },
      async changecategory(category) {
        let { data } = await Authentication.sendRequest(
          this,
          "/api/v1/changecategory",
          "post",
          { id: category.id, name: category.name, new_images: this.new_images }
        );
        if(data.success){
          this.$refs['filesedit'].reset()
        }
        this.$emit('show-toast', data,false)            
      },
      handleFileUpload(event){
          if(event.target.files.length>0){
              this.new_images = event.target.files;
              var reader = new FileReader();
            var vm = this;
            reader.onload = function(e) {
              vm.imagesrc = e.target.result;
            }
            reader.readAsDataURL(event.target.files[0]);
          }else{
              this.new_images =''
              this.imagesrc = null
          }
          
      },    
    },
  };
  </script>
  <style lang="scss">
  </style>