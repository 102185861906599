<template>
<div>
    <footer>
    <div class="footer">
        <b-container>
            <b-row align-v="center">
                <b-col class="my-2">
                    <div class="ql-editor  ql-viewer" v-html="settings.footer" v-if="settings && settings.footer">
                    </div>
                </b-col>
                <b-col class="text-center text-mb-right my-2" cols="12" md="3">
                    <social :settings="settings"></social>
                </b-col>
            </b-row>                     
        </b-container>      
    </div>
  </footer>
</div>  
</template>

<script>
import Social from "@/components/components/Social";
export default {   
    props:['settings'], 
  components: {
    "social": Social
  },
};
</script>

<style lang="scss">
    .footer{
        background-color: #1f1f1f;
        color:#fff;
        padding:30px 0;
    }
    @media (max-width: 767px) {
        .footer{
            padding-bottom: 80px;
        }
    }
</style>