<template>
    <div class="loaderSend">
        <b-icon icon="envelope-fill" animation="cylon" font-scale="4"></b-icon>      
    </div>
</template>

<script>
export default {
  
};
</script>

<style lang="scss">
    .loaderSend{
        background-color: rgba(0, 0, 0, 0.8);
        position: fixed;
        width: 100vw;
        height: 100vh;
        top:0;
        left:0;
        color:#fff;
        z-index: 5000;  
        display: flex;
        align-items: center;
        justify-content: center;            
    }

</style>