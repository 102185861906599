<template>
  <div class="page-admin-block">
    <b-container class="py-4">
      <h3>В наличии</h3>
      <hr />
      <div class="ml-3">
        <h5 class="mt-1">Добавить в наличии</h5>
        <b-form>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Название" class="mt-1">
                <b-form-input
                  id="stockproduct_name"
                  type="text"
                  label="Name"
                  v-model="newstockproduct.name"
                  required
                  placeholder="Название"
                ></b-form-input>
              </b-form-group>
            </b-col>           
          </b-row>

          <b-button
            class="my-2"
            variant="secondary"
            @click="addProduct()"
            title="Добавить товар"
          >
            Добавить
          </b-button>
        </b-form>
      </div>
      <hr />
      <div class="ml-3">
      <h5>Список в наличии</h5>
      <b-row align-h="center">
        <b-col
          cols="12"
          md="6"
          lg="4"
          class="mb-4"
          v-for="stockproduct in stockproducts"
          :key="stockproduct.id"
        >
          <card-stockproduct
            :settings="settings"
            v-on:updateList="updateList"
            v-on:show-toast="showToast"
            :stockproduct="stockproduct"
            :admin="true"
          ></card-stockproduct>
        </b-col>
      </b-row>

      <div class="text-center mb-5">
        <b-button v-if="showmorebutton" @click="Showmore()"
          >Показать еще</b-button
        >
      </div>
      </div>
    </b-container>
    <b-toast
      :title="toast.title"
      v-model="toast.show"
      :variant="toast.variant"
      autoHideDelay="5000"
      >{{ toast.message }}</b-toast
    >
  </div>
</template>
<script>
import Authentication from "@/components/pages/Authentication";
import CardStockProduct from "@/components/components/CardStockProduct";
export default {
  props:['settings'],
  data() {
    return {
      stockproducts: [],
      page: 1,
      count: 8,
      showmorebutton: true,
      toast: {
        show: false,
        title: "",
        variant: "",
        message: "",
      },
      newstockproduct: {
        name: "",
      },
    };
  },
  components: {
    "card-stockproduct": CardStockProduct,
  },
  created() {
    this.getAllProducts();
  },
  methods: {
    showToast(data) {
      if (data) {
        if (data.success) {
          this.toast = {
            show: true,
            title: "Выполнено",
            variant: "success",
            message: data.message,
          };
        } else {
          this.toast = {
            show: true,
            title: "Ошибка",
            variant: "danger",
            message: data.message,
          };
        }
      }
    },
    Showmore() {
      this.page++;
      this.getAllProducts();
    },
    updateList() {
      this.stockproducts = [];
      this.page = 1;
      this.getAllProducts();
    },
    async getAllProducts() {
      let { data } = await Authentication.sendRequestNotAuth(
        this,
        "/api/v1/stockproducts?page=" +
          this.page +
          "&count=" +
          this.count,
        "get"
      );
      if (data) {
        if (data.length < this.count) {
          this.showmorebutton = false;
        } else {
          this.showmorebutton = true;
        }
        this.stockproducts.push(...data);
      }
    },
    async addProduct() {
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/addstockproduct",
        "post",
        this.newstockproduct
      );
      this.showToast(data);
      if (data.success) {
        this.$router.push("/admin/stockproduct/" + data.stockproduct.id).catch(() => {});
      }
    },
  },
};
</script>
<style lang="scss">
/*.cards-stockproduct{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}*/
</style>