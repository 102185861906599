<template>
  <div>
    <loader v-if="load"></loader>
    <b-modal id="mail-send-valid" header-bg-variant="success" centered hide-footer>
      <div class="d-block">
        {{ success_message }}
      </div>
    </b-modal>
    <b-modal id="mail-send-not-valid" header-bg-variant="danger" centered hide-footer>
      <div class="d-block">
        {{ error_message }}
      </div>
    </b-modal>
    <b-modal id="modal-feedback-form" hide-footer :title="'Отправить заявку менеджеру ' + (manager ? manager.name : '')"
      scrollable centered @hidden="resetParams">
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group class="mt-2" label="Ваше имя">
              <b-input v-model="params.username" placeholder="ФИО"></b-input>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group class="mt-2" label="Номер телефона">
              <b-form-input v-model="params.phone" v-mask="'+7 (###) ###-##-##'" placeholder="+7 (###) ###-##-##"
                id="phone" required :state="validPhone(params.phone)"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" v-if="settings.typesfeedback">
            <b-form-group class="mt-2" label="Как с вами связаться">
              <b-form-select v-model="params.type_feedback" :options="types_feedback"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" v-if="categories">
            <b-form-group class="mt-2" label="Выбор авто">
              <b-form-select v-model="params.current_category"
                :options="[{ value: -1, text: 'Помочь с выбором' }, ...categories]"
                @change="getProducts()"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" v-if="products">
            <b-form-group class="mt-2" label="модель">
              <b-form-select v-model="params.current_product" :options="products"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

      </b-form>
      <b-button class="mt-2" block @click="sendMail()"
        :disabled="!validPhone(params.phone) || !params.username">Отправить</b-button>
    </b-modal>

  </div>
</template>

<script>
import Authentication from "@/components/pages/Authentication";
import LoaderSend from "@/components/components/LoaderSend";
export default {
  props: ['categories', 'manager','settings'],
  data() {
    return {
      load: false,
      error_message: "",
      success_message: "",
      params: {
        current_category: -1,
        current_product: null,
        username: '',
        phone: null,
        type_feedback: 'WhatsApp',
      },
      products: null,
      types_feedback: this.settings.typesfeedback.split(",").map(el=>{ return {value: el, text: el}})
    };
  },
  components: {
    'loader': LoaderSend
  },
  mounted() {
  },
  methods: {
    validPhone(val) {
      return val == null ? null : val.length == 18;
    },

    async getProducts() {
      this.params.current_product = null
      this.products = null
      if (this.params.current_category != -1) {
        let { data } = await Authentication.sendRequestNotAuth(
          this,
          "/api/v1/getproductsfront?category_id=" + this.params.current_category,
          "get",
        );
        if (data && data.length > 0) {
          this.params.current_product = data[0].id
          this.products = data.map(el => { return { value: el.id, text: el.name } })
        }
      } 
    },
    async resetParams(){
      this.params = {
            current_category: -1,
            current_product: null,
            username: '',
            phone: null,
            type_feedback: 'WhatsApp',
          }
      this.products=null    
    },
    async sendMail() {
      this.load = true;
      if (this.validPhone(this.params.phone) && this.params.username) {
        let { data } = await Authentication.sendRequestNotAuth(
          this,
          "/api/v1/sendMail",
          "post",
          { ...this.params, manager: this.manager.id }
        );
        if (data.success) {
          this.success_message = "Ваше письмо успешно отправлено";
          this.$bvModal.show("mail-send-valid");
          this.$bvModal.hide('modal-feedback-form')          
        } else {
          this.error_message = "Ошибка при отправке, попробуйте позднее";
          this.$bvModal.show("mail-send-not-valid");
        }
      } else {
        this.error_message =
          "Неверно заполнены поля, проверте правильность заполнения";
        this.$bvModal.show("mail-send-not-valid");
      }
      this.load = false;
    },
  },

}
</script>

<style lang="scss"></style>
