<template>
      <div class="bg-main-slider top-header">
        <b-container  v-if="settings && settings.mainblock" class="my-4 my-md-0">             
              <b-row  align-h="center" align-v="end" >
                <b-col class="d-none d-md-block">
                  <a href="/"><img src="/img/logo.png" alt="" width="80%" class="mr-2"/></a>
                </b-col>
                <b-col cols="12" md="8" lg="9">
                  <div class="home-infoblock1-text text-center py-5 px-3 ql-editor ql-viewer shadow-green" v-html="settings.mainblock">                   
                  </div>            
                </b-col>
              </b-row>   
      </b-container>
      </div>
</template>
<script>
export default {
  props:['settings'],
    data() {
    return {
    };
  },

};
</script>
<style lang="scss">
    .bg-main-slider{
        //background-image: url("~@/assets/images/main-bg1.jpg");
        background: radial-gradient(#ffffff, #797979);
        background-size: cover;
        background-position: center;  
        padding-bottom: 50px;  
        img.shadow-green{
          border-radius: 40px;
            transition: 0.1s;
            &:hover{
                box-shadow: rgb(37 211 102) 0px 0px 3px;
            }
        }    
    }
    .more_inforamtion{
        text-decoration: none;
        font-weight: 600;
        color: #ffffff;
        padding: 15px 20px;
        display: block;
        background-color: $color1;
        text-align: center;
        &:hover{
            text-decoration: none;
            background-color: $color2;
            color: #fff;

        }
    }
    .price-slider{
        text-align: center;
        font-size: 30px;
    }
    @media (max-width: 1024px) {
        .bg-main-slider.top-header{ 
            img{
              border-radius: 0;
            }
        }
    }
    @media (max-width: 767px) {
        .bg-main-slider.top-header{
            padding-bottom: 30px;  
            padding-top: 65px; 
        }
        .price-slider{
          font-size: 24px;
         }
  }

.animate {
  transition-delay: .5s;
  transition-duration: 0.8s;
  transition-timing-function: ease;
}

.slide-scale {
  transform: scale(0.5);
  opacity: 0;
}

.slide-scale.animate-active {
  transform: scale(1);
  opacity: 1;
}

.slide-left {
  transform: translateX(100px);
  opacity: 0;
}

.slide-left.animate-active {
  transform: translateX(0);
  opacity: 1;
}

@media (max-width: 767px) {
  .slide-left {
    transform: translateX(0);
    opacity: 0;
  }

}


.home-infoblock1-text{
  padding: 20px;
  background: radial-gradient(#ffffff, #797979);
  border-radius: 40px;
  border-radius: 15px;
}
@media (max-width: 1024px) {
    .home-infoblock1-text{
        border-radius: 0;

    }


}  

</style>