<template>  
        <div class="mobile-menu">
            <div :class="{ bg: showMenu }" class="bg-top">
                <div class="h-100">
                    <div class="menu-btn" @click="menuDisplay()"><span></span></div>
                    <b-row  class="h-100 text-center" style="margin-left: 40px;" align-v="stretch" no-gutters>
                        <b-col v-for="(route, index) in $router.options.routes.filter((el) => el.meta.showmenu && !el.meta.admin)" :key="index">
                            <a :href="route.path" class="links-list text-nowrap px-1" >
                             {{ getCaption(route) }}
                            </a>
                        </b-col>
                    </b-row>
                </div>                    
            </div>               
            <div class="menu" :class="{ menu_active: showMenu }">       
                <div class="menu-btn-block">
                    <div class="menu-btn" @click="menuDisplay()" v-if="showMenu"><span></span></div>
                </div>        
                <nav class="menu-list">
                    <a href="/"><img src="/img/logo.png" alt="" height="70px" class="py-2"></a>
                    <a v-for="(route,index) in routesShow.filter(el=>el.meta.showmobilemenu==true)" :key="index" :href="route.path" @click.prevent="menuDisplay(route)">
                       <div> {{getCaption(route)}} </div>
                        </a>                
                </nav>
            </div>
        </div>            
</template>

<script>
export default {
    data(){
        return {
            showMenu:false,
            routesShow:this.routes
        }
    },
    props:['routes','pages'],
    methods: {
        getCaption(route){
            let alias = route.path.split("/")
            let pagecaption = this.pages.find(page => page.alias == alias[alias.length-1]) 
            return (pagecaption && pagecaption.caption) ? pagecaption.caption : route.name
        },  
        menuDisplay(route){
            if(route){
                if(route.children){
                    this.routesShow=route.children
                }else{
                    this.showMenu=!this.showMenu               
                    this.$router.push(route).catch(()=>{})
                    this.routesShow=this.routes
                }                
            }else{
                this.showMenu=!this.showMenu
                this.routesShow=this.routes
            }
        }
  },
};
</script>

<style lang="scss" scoped>

.bg-top{
    box-shadow: 0px -2px 5px -2px rgba(0,0,0,0.75);
    width: 100vw;
    height: 40px;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 5;
    border-bottom: 1px solid #ccc;
    .links-list{
        font-size: 10px;
        color: #fff;
        text-decoration: none;
        line-height: 40px;
        font-weight: 600;
        height: 100%;
        border-left: 1px solid #fff ;
    display: inline-block;
    width: 100%;
    background-color: $color2;
    }
}
.bg{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left:0;
    z-index: 98;
    background-color: #fff;
    opacity: 0.2;
  }
  .menu {
    position: fixed;
    left: 0;top: 0;
    z-index: 99;
    width: 100%;
    min-width: 300px;
    min-height: -webkit-fill-available;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    transition: 0.5s;
    transform: translateX(-100%);   
    border-right: 1px solid #ccc;
    &-list{
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 100%;
        flex-direction: column;
        padding-bottom: 180px;
        a{
            display: block;
            text-decoration: none;
            text-transform: uppercase;
            font-weight: 700;
            color:#fff;
            text-align: center;
            height: 100%;
            width: 90vw;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top: 1px solid #fff;
            &:last-child{
                border-bottom: 1px solid #fff;
            }
            &:first-child{
                border-top: none;
            }
        }
    }
    &-btn{
        display: block;
        width: 40px;
        height: 40px;
        position: absolute;
        background-color: $color2;
        cursor: pointer;
        &-block{
            position: absolute;
            right: -50px;
            bottom: 0;
        }
        span,span::before,span::after{
            position: absolute;
            top: 50%; margin-top: -2px;
            left: 50%; margin-left: -10px;
            width: 20px;
            height: 3px;
            background-color: #fff;            
        }
        span{
            &::before{
                content: '';
                display: block;
                transition: 0.2s;  
                transform: translateY(-6px);              
            }
            &::after{
                content: '';
                display: block;
                transition: 0.2s;
                transform: translateY(6px);
            }
        }
    }
    &_active{
        transform: translateX(0%);
        background-color: $color2;
        .menu-btn-block{
            right: auto;   
            bottom: 100px;         
            .menu-btn {
                position:relative;
                background-color: transparent;
                span{
                    background-color: transparent;
                    &::before{
                        transform: rotate(-45deg);
                        width: 30px;
                        transform-origin: 50% 50%;
                        height: 4px;
                    }
                    &::after{
                        transform: rotate(45deg);
                        width: 30px;
                        transform-origin: 50% 50%;    
                        height: 4px;  
                  }
                }
            }           
        }
    }
    @media(min-width:768px){
        width: 50%;
    }
    @media(min-width:992px){
        width: 30%;
    &-btn{
        width: 70px;
        height: 70px;
        &-block{
            right: -75px;
        }
        span,span::before,span::after{
            width: 40px;
            height: 4px;
            margin-left: -20px;
            margin-top: -2px;            
        }
        span{
            &::before{
                transform: translateY(-10px);              
            }
            &::after{
                transform: translateY(10px);
            }
        }
    }
    &_active{
        .menu-btn-block{
            right: -75px;
            .menu-btn span{
                &::before,&::after{
                    width: 40px;
                }
                 
            }           
        }
    }
    }

  } 

</style>