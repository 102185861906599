<template>
    <div>
        <hr>
        <h4>Вопросы и ответы</h4>
        <hr>
        <b-form class=" mb-2 ml-3">
          <b-input-group prepend="Вопрос">
          <b-form-input
            type="text"
            label="Caption"
            v-model="newfaq.caption"
            required
            placeholder="Вопрос"
          ></b-form-input>
        </b-input-group>   
        <b-form-group label="Ответ"  class="mt-2">
          <editor v-model="newfaq.text"></editor>
        </b-form-group>                       
        <b-button
          class="my-3"
          variant="secondary"
          @click="addfaq()"
          title="Добавить вопрос/ответ"
        >
          Добавить вопрос/ответ
        </b-button>
      </b-form>  
      <hr>
      <div class="ml-3">
        <faq-edit :faq="faq" v-for="faq in faqs" :key="faq.id" v-on:delete-faq="deletefaq" class="mb-2"></faq-edit>
      </div>      
    </div>
  </template>
  <script>
  import Authentication from "@/components/pages/Authentication";
  import FaqEdit from "@/components/components/FaqEdit";
  import Editor from "@/components/components/Editor.vue";
  
  export default {
    data() {
      return {
        faqs: [],
        newfaq: {
            caption: "",
            text:"",                     
        },
      };
    },
    components:{
        'faq-edit':FaqEdit,
        'editor': Editor
    },
    created() {
      this.getfaqs();
    },
    methods: {           
      async getfaqs() {
        let { data } = await Authentication.sendRequestNotAuth(
          this,
          "/api/v1/faqs",
          "get",
        );
        if (data) {
          this.faqs = data;
        }
      },
      async addfaq() {
        let { data } = await Authentication.sendRequest(
            this,
            "/api/v1/addfaq",
            "post",
            this.newfaq
        );  
        if(data.success){
            this.faqs.push(data.faq)
            console.log(this.faqs)
            this.newfaq= {
                caption: "",
                text:"",          
            }
        }
      },
      async deletefaq(id) {
        let { data } = await Authentication.sendRequest(
            this,
            "/api/v1/deletefaq/"+id,
            "post"
        );  
        if(data.success){
            this.faqs = this.faqs.filter(faq=>faq.id!=id)  

        }
      },
    },
  };
  </script>
  <style lang="scss">
  </style>