<template>
  <div v-if="configuration_edit">
    <b-row class="mb-2" no-gutters>
      <b-col cols="11" md="6">
        <div class="bg-white p-1 position-relative border" v-b-toggle="'collapse-'+configuration_edit.id">        
          <div class="d-inline-block align-middle mx-2"><b>{{configuration_edit.name}}</b></div>
            <span class="when-opened float-right" style="font-size: 20px;">
              <b-icon icon="x" aria-hidden="true"></b-icon>            
            </span>
            <span class="when-closed float-right" style="font-size: 20px;">
              <b-icon icon="arrow-down-short" aria-hidden="true"></b-icon>           
            </span>                
        </div>
      </b-col>
      <b-col>
        <b-button @click="deleteConfiguration()" class="btn btn-danger" title="удалить">
            <b-icon icon="trash" aria-hidden="true"></b-icon>
          </b-button>
      </b-col>
    </b-row>
    
    <b-collapse :id="'collapse-'+configuration_edit.id"> 
      <b-form class="border p-2">
        <b-row>
          <b-col cols="12" md="6">
            <b-input-group prepend="Название" class="mb-3">
          <b-form-input
            id="configuration_name"
            type="text"
            label="Name"
            v-model="configuration_edit.name"
            required
            placeholder="Название"
          ></b-form-input>
        </b-input-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-input-group prepend="Цена">
          <b-form-input
            type="text"
            label="Configuration"
            v-model="configuration_edit.price"
            required
            placeholder="Цена"
          ></b-form-input>
        </b-input-group> 
          </b-col>
        </b-row>        
        <b-form-group label="Описание"  class="mt-2">
          <editor v-model="configuration_edit.description"></editor>
        </b-form-group>         
        <b-button
          class="mt-3"
          variant="secondary"
          @click="changeconfiguration()"
          title="Сохранить"
        >
          Сохранить
        </b-button>
      </b-form>
    </b-collapse> 
    <b-toast
      :title="toast.title"
      v-model="toast.show"
      :variant="toast.variant"
      autoHideDelay="5000"
      >{{ toast.message }}</b-toast
    >
  </div>
</template>
<script>
import Authentication from "@/components/pages/Authentication";
import Editor from "@/components/components/Editor.vue";
export default {
  props:['configuration'],
  data() {
    return {
      configuration_edit: this.configuration,
      toast: {
        show: false,
        title: "",
        variant: "",
        message: "",
      },
    };
  },
  components:{
        'editor': Editor
    },
  methods: {
    showToast(data) {
      if (data) {
        if (data.success) {
          this.getconfiguration();
          this.toast = {
            show: true,
            title: "Выполнено",
            variant: "success",
            message: data.message,
          };
        } else {
          this.toast = {
            show: true,
            title: "Ошибка",
            variant: "danger",
            message: data.message,
          };
        }
      }
    },
    download(id, download = false) {
      return Authentication.download(id, download);
    },    
    async getconfiguration() {
      let { data } = await Authentication.sendRequestNotAuth(
        this,
        "/api/v1/configuration/"+this.configuration_edit.id,
        "get",
      );
      if (data) {
        this.configuration_edit = data;
      }
    },
    async changeconfiguration() {
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/changeconfiguration",
        "post",
        {...this.configuration_edit}
      );
      this.showToast(data);
    },
    deleteConfiguration(){
      this.$emit('delete-configuration', this.configuration_edit.id)
    }
  },
};
</script>
<style lang="scss">
.collapsed > .when-opened,
    :not(.collapsed) > .when-closed {
        display: none;
    }
</style>