<template>
  <div class="page-admin-block" style="padding-bottom: 40vh;">
    <b-container>
      <h3>Условия</h3>
      <hr>
      <page-editor :alias="'contract'"></page-editor>
      <faqs-edit></faqs-edit>
    </b-container>
  </div>
</template>
<script>
import PageEditor from "@/components/components/PageEditor";
import FaqsEdit from "@/components/components/FaqsEdit";

export default {
  components: {
    'page-editor': PageEditor,
    'faqs-edit':FaqsEdit
  },  
};
</script>
<style lang="scss">
</style>