<template>
  
  <div class="header" :style=" ($route.meta.headerbgcolor?'background:radial-gradient(#fff, rgb(201 201 201));':false)">
      <b-container>
        <b-row class="desktop-menu" align-v="center">
          <b-col cols="8" md="auto">
            <div class="logo text-nowrap">          
              <a href="/"> <img src="/img/logo.png" alt="" height="50px" class="mr-1 d-md-none"> ПЕРВЫЙ ВОЛЬТ</a>
            </div>
          </b-col>
          <b-col class="d-none d-md-block">
            <div class="menu-top-list">
              <b-row align-h="center">
                <b-col v-for="(route, index) in $router.options.routes.filter(
              (el) => el.meta.showmenu && !el.meta.admin
            )" :key="index" cols="auto">
                  <a :href="route.path" class="links-list text-nowrap" >
                  {{ getCaption(route) }}
                </a>
                </b-col>
              </b-row>
            
        </div>
          </b-col>
          <b-col cols="4" md="auto" class="text-right">
            <social :settings="settings"></social>
          </b-col>        
        </b-row>                      
      </b-container>
    <header-mobile
      :pages="pages"
      :routes="$router.options.routes.filter((el) => !el.meta.admin)"
    ></header-mobile>
  </div>
</template>

<script>
import HeaderMobile from "@/components/components/HeaderMobile";
import Social from "@/components/components/Social";

export default {
  props: ["pages" ,'settings'],
  data() {
    return {
      test: {},
    };
  },
  methods: {
    getCaption(route) {
      let alias = route.path.split("/");
      let pagecaption = this.pages.find(
        (page) => page.alias == alias[alias.length - 1]
      );
      return pagecaption && pagecaption.caption
        ? pagecaption.caption
        : route.name;
    },
  },
  components: {
    "header-mobile": HeaderMobile,
    "social": Social
  },
};
</script>

<style lang="scss">
.header {
  padding-top: 40px;
  padding-bottom: 40px;
  position: absolute;
  width: 100%;
}
  .menu-top-list {
    text-align: center;
    a,
    .link-header {
      display: inline-block;
      width: 100%;
      vertical-align: top;
      color: #222;
      font-size: 18px;
      text-align: center;
      line-height: 45px;
      white-space: nowrap;
      cursor: pointer;
      &::before,
      &::after {
        content: "";
        width: 0px;
        height: 1px;
        display: block;
        background: $color2;
        transition: 0.5s;
        text-align: right;
      }
      &:hover {
        text-decoration: none;
        &::before,
        &::after {
          width: 100%;
        }
      }
    }
  }
  .logo {
    font-size: 36px;
    font-weight: 600;
    a {
      color: #282828;
      display: inline-block;
      text-decoration: none;
      &::before,
      &::after {
        content: "";
        width: 0px;
        height: 2px;
        display: block;
        background: $color2;
        transition: 0.5s;
        text-align: right;
      }
      &:hover {
        text-decoration: none;
        &::before,
        &::after {
          width: 100%;
        }
      }
    }
  }
  .social{
    width: 40px;
    height: 40px;
    margin-left: 5px;
    border-radius: 50%;
    padding: 1px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: 0.3s;
    &:hover{
      transform: scale(1.1);
    }
    img{      
      width: 100%;
      height: 100%;
    }
  }

.mobile-menu {
  display: none;
}

@media (max-width: 992px) {
  .desktop-menu .logo {
    font-size: 28px;
  }
  .header {
    .menu-top-list a{
          font-size: 16px;
    }
  }
}
@media (max-width: 767px) {
  .header {
    padding-top: 5px;
    padding-bottom: 5px;
    position: fixed;
    top:0;
    left:0;
    z-index: 1000;
    box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.315);
    width: 100vw;
    background: $color1 !important;
    .menu-top-list a{
          font-size: 16px;
    }
  }
  .desktop-menu .logo a{
    font-size: 20px;
    color: #fff;
  }
  .social{
    width: 25px;
    height: 25px;
  }
  .mobile-menu {
    display: block;
  }
}

</style>