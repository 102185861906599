<template>
 <div :class="{ menu_active: showMenu }">
     <span @click="showMenuChange()" class="button-admin-menu">
         <b-icon icon="X" v-if="showMenu" aria-hidden="true"></b-icon>
         <b-icon icon="list" v-else aria-hidden="true"></b-icon>
    </span>     
     <div class="admin-header" >
    <div>
        <div class="admin-header-label">МЕНЮ</div>
    <div class="menu-admin">        
        <nav>
            <a v-for="(route,index) in $router.options.routes.filter(el=>el.meta.showmenu && el.meta.admin)" :class="{active: route.path==$router.currentRoute.path}" :key="index" :href="route.path" >{{getCaption(route)}}</a>  
            <a @click="submitSignout()" class="loginout">Выход <timer></timer></a>                  
        </nav>        
    </div> 
    </div>       
    </div>   
    </div>
</template>

<script>
import Authentication from "@/components/pages/Authentication";
import Timer from "@/components/components/Timer";
export default {
    props:['pages'],  
    data(){
        return {
            showMenu:false,
        }
    },
  methods: {
    showMenuChange(){
        this.showMenu=!this.showMenu
    },  
    getCaption(route){
        let alias = route.path.split("/")
        let pagecaption = this.pages.find(page => page.alias == alias[alias.length-1]) 
        return (pagecaption && pagecaption.caption) ? pagecaption.caption : route.name
    },  
    submitSignout() {
      Authentication.signout(this, "/admin");
    },
  },
  components:{
      'timer': Timer
  }
};
</script>

<style lang="scss">
.button-admin-menu{
    position: fixed;
    transition: 0.5s;
    z-index: 1005;
    font-size: 30px;
    display: inline-block;
    vertical-align: middle;
    line-height: 0;
    padding: 5px;
    cursor: pointer;
    left:0;
    top:0;
}
.admin-header{
    position: absolute;
    transition: 0.5s;
    left: -250px;
    width: 250px;
    height: 100vh;
    padding-bottom: 70px;
    overflow: auto;
    border-right: 1px solid #ccc;
    background-color: #f1f1f1;
    z-index: 1000;
    &-label{
        width:100%;
        background-color: #6c757d;
        padding: 10px;
        color: #fff;
        text-align: center;
    }    
    a{
        display: block;
        padding: 10px;
        border-bottom: 1px solid #ccc;
        color:$color ;
        &.active{
            background-color: #fff;
        }
        cursor: pointer;
        &:hover{
            text-decoration: none;
            background-color: #fff;
        }
        &.loginout{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 249px;
            border-top: 1px solid #ccc;
            color:#fff;
            background-color: #6c757d;
            &:hover{
                background-color: #5a6268;
            }
        }

    }
}
.menu_active{
    position: fixed;
    z-index: 1000;
    .admin-header{
        left: 0;
    }
    .button-admin-menu{
        left:210px;
        color:#fff;
    }
}

.admin{
    min-height: 100vh;
    background-color: #f5f5f5;
}

</style>