<template>      
    <div class="pt-3 border h-100 padding-bottom position-relative">              
        <div class="p-3" @click="showSlogan()">
          <div class="circle-image">
            <img :src="download(contact.photo.id)"/>
          </div>
        </div>
        <div class="text-center">
          <h3>{{contact.name}}</h3>          
          <small>{{contact.description}}</small><br>
          <transition name="fade">
          <div class="contact-slogan mt-3" v-if="show_slogan">
            {{contact.slogan}}
          </div> 
          </transition>        
        </div>  
        <div class="button-bottom text-center">
          <b-button @click="showSlogan()" class="mr-1" v-if="settings.managerbutton1"><span v-html="settings.managerbutton1"></span></b-button>
          <b-button @click="$emit('set-manager',contact)" v-if="settings.managerbutton2"><span v-html="settings.managerbutton2"></span></b-button>
        </div>                                
    </div>      
  </template>
  <script>
  import Authentication from "@/components/pages/Authentication";
  export default {
    props:['contact','settings'],
    data() {
      return {
        show_slogan:false,
      };
    },
    methods: {
      showSlogan(){
        this.show_slogan=!this.show_slogan
      },
      download(id, download = false) {
        return Authentication.download(id, download);
      },     
    },
  };
  </script>
  <style lang="scss">
    .button-bottom{
      white-space: nowrap;
      width: 100%;
      position: absolute;
      bottom: 15px;
      .btn{
        padding: .375rem;
        background-color: $color2;
        color: #fff;
      }
    }
    .padding-bottom{
      padding-bottom: 70px;
    }
  </style>