<template>
  <div>
    <transition name="fade">
        <loader-page v-show="loadpage"></loader-page>
      </transition>
    <h4 class="mb-1" v-if="page">{{page.caption}}</h4>  
    <div class="ql-snow" v-if="page.text"> 
      <div class="ql-editor  ql-viewer" v-html="page.text"></div>
    </div>
    <div class="document" v-for="(document,index) in page.fileDetails" :key="index">
        <a :href="download(document.id,'download')" title="скачать" download class="document_download">
          <b-icon icon="download"></b-icon>
        </a>
        <span v-if="document.mimetype!='application/pdf'">
            {{document.text}}
        </span>
        <a v-else :href="download(document.id)" target="_blank" title="просмотр" class="document_show">
          {{document.text}}
        </a>
      </div>
  </div>
</template>
<script>
import Authentication from "@/components/pages/Authentication";
import LoaderPage from '@/components/components/LoaderPage'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css' 
export default {
  data() {
    return {
      page: {},     
      loadpage:false,     
    };
  },
  components:{
        'loader-page':LoaderPage
    },
  props:['alias'],
  created() {
    this.getPage();
  },
  methods: {    
    async getPage() {       
      this.loadpage = true 
      let { data } = await Authentication.sendRequestNotAuth(
        this,
        "/api/v1/page",
        "post",
        { alias: this.alias }
      );
      if (data) {
        this.page = data;
      }
      this.loadpage = false
    }, 
    download(id,download=false){
        return Authentication.download(id,download)
    }  
  },
};
</script>
<style lang="scss">
.documents{
  margin-top: 20px;
  margin-bottom: 30px;
}
.document {
  a {
    display: inline-block;
    padding: 5px;
    color: #212529;
    &:hover {
      text-decoration: none;
      color: $hover-color;
    }
  }
  .document_download {
    &:hover {
      color: $hover-color;
    }
  }
}
</style>