<template>
   <div>
        <b-modal id="timer-message" title="" header-bg-variant="secondary" hide-footer>
            <p class="text-center">
                Время сеанса заканчивается через <br> {{ diff.hour }} : {{ diff.minute }} : {{ diff.second }}
            </p>
        </b-modal>
    {{ diff.hour }} : {{ diff.minute }} : {{ diff.second }}
   </div> 
</template>
<script>
const getDateDiff = (date1, date2) => {
  const diff = new Date(date2.getTime() - date1.getTime());  
  return {
    year: diff.getUTCFullYear() - 1970,
    month: diff.getUTCMonth(),
    day: diff.getUTCDate() - 1,
    hour: (diff.getUTCHours().toString().length<2?'0':'')+diff.getUTCHours(),
    minute:(diff.getUTCMinutes().toString().length<2?'0':'')+diff.getUTCMinutes(),
    second: (diff.getUTCSeconds().toString().length<2?'0':'')+diff.getUTCSeconds(),
  };
};
import Authentication from "@/components/pages/Authentication";
export default {
  name: "App",
  data() {
    return {
      futureDate: new Date(+this.$cookie.get('expires')),
      diff: {},
      show:true,
      timer: undefined,
    };
  },
  methods: {
    getDiff() {
      if(((this.futureDate.getTime() - new Date().getTime())< 3*60*1000) && this.show){
            this.$bvModal.show('timer-message')
            this.show = false;            
      } 
      if(((this.futureDate.getTime() - new Date().getTime())< 0) && this.$cookie.get('expires') && !Authentication.checkAuthentication()){
            Authentication.signout(this, "/admin");
       } 
      this.diff = getDateDiff(new Date(), this.futureDate);
    },
  },
  beforeMount() {    
    this.timer = setInterval(this.getDiff, 1000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>