<template>     
    <quill-editor v-model="content" @change="onEditorChange"
                ref="myQuillEditor"
                :options="editorOption"
                @ready="onEditorReady($event)">
    </quill-editor>         
</template>
<script>
import { quillEditor } from 'vue-quill-editor'
import Quill from 'quill'
import { ImageDrop } from 'quill-image-drop-module'
import ImageResize from '@taoqf/quill-image-resize-module'
import VideoResize from 'quill-video-resize-module'
Quill.register('modules/imageDrop', ImageDrop)
Quill.register('modules/imageResize', ImageResize)
Quill.register('modules/VideoResize', VideoResize);


let Embed = Quill.import('blots/embed');
class BreakBlock extends Embed {}
BreakBlock.blotName = 'br';
BreakBlock.tagName = 'br';
Quill.register(BreakBlock);

const fontSizeArr = ['10px','12px','14px','16px','18px','20px','24px','32px','42px','54px','68px','84px','98px'];

var Size = Quill.import('attributors/style/size');
Size.whitelist = fontSizeArr;
Quill.register(Size, true);


export default {
  data() {
    return {
      content: this.value,
      editorOption: {
        debug:false,
        modules: {
            toolbar:{container:[
                [{ 'size': fontSizeArr }],
          [ 'bold', 'italic', 'underline', 'strike' ],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'script': 'super' }, { 'script': 'sub' }],
          [{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block' ],
          [{ 'list': 'ordered' }, { 'list': 'bullet'}, { 'indent': '-1' }, { 'indent': '+1' }],
          [ {'direction': 'rtl'}, { 'align': [] }],
          [ 'link', 'image', 'video', 'formula' ],
          ['br'],
          [ 'clean' ]
            ],
            handlers: {
                'br': function () {                            
                        var range = this.quill.getSelection();
                        if (range) {
                            this.quill.insertEmbed(range.index,"br","null")
                        }
                    }
                }
            },
            imageDrop: true,
            imageResize: true,   
            VideoResize:true         
        },        
        theme: 'snow'
      },
      text:''
    };
  },
  props:['value'],
  watch: { 
    value: function(newVal) {
      this.content=newVal 
     }
    },
  components: {
    quillEditor
  },
  created(){

  },
  methods: {  
    onEditorChange({ html })  {
      this.$emit('input', html)
    },
    onEditorReady(quill){
        quill.container.firstChild.innerHTML = this.value
    },             
  },
};
</script>
<style lang="scss">
.html-view{
    border: 1px solid #ccc;
}
.ql-toolbar{
  background-color:#e9ecef;
}
.ql-container{
    font-size: 16px;
    background-color: #fff;
    min-height: 120px;
}
.col4 {
    display: inline-block;
    width:calc(100% / 4);
    vertical-align: top;
    margin-bottom: 15px;
}

.col5 {
    display: inline-block;
    width:calc(100% / 5);
    vertical-align: top;
    margin-bottom: 15px;
}

.col3 {
    display: inline-block;
    width:calc(100% / 3);
    vertical-align: top;
    margin-bottom: 15px;
}

.col2 {
    display: inline-block;
    width:calc(100% / 2);
    vertical-align: top;
    margin-bottom: 15px;
}

.ql-col5:after {
  content: "\2630 5";
}

.ql-col4:after {
  content: "\2630 4";
}
.ql-col3:after {
  content: "\2630 3";
}
.ql-col2:after {
  content: "\2630 2";
}


@media (max-width:768px){
    .col2 {
        width:100%;
    }
    .col3 {
        width:calc(100% / 2);
    }
    .col4 {
        width:calc(100% / 2);
    }
    .col5 {
        width:calc(100% / 3);
    }
}
@media (max-width:420px){
    .col3 {
        width:100%;
    }
    .col4 {
        width:100%;
    }
    .col5 {
        width:calc(100% / 2);
    }
}

.ql-br:after {
  content: "\21B5";
}


.ql-snow{
.ql-picker{
    &.ql-size{
        .ql-picker-label,
        .ql-picker-item{
            &::before{
                content: attr(data-value) !important;
            }
        }
    }
}
}

.ql-editor{
    padding:0
}

.page-admin-block{
    padding-top: 30px;
  .ql-editor{
    padding:10px
}
}

.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 1em;
}
.ql-editor .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 2em;
}
.ql-editor .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 3em;
}
.ql-editor .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 4em;
}
.ql-editor .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 5em;
}
.ql-editor .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 6em;
}
.ql-editor .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 7em;
}
.ql-editor .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 8em;
}
.ql-editor .ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 9em;
}
.ql-editor .ql-indent-10:not(.ql-direction-rtl) {
    padding-left: 10em;
}
.ql-editor .ql-indent-11:not(.ql-direction-rtl) {
    padding-left: 11em;
}
.ql-editor .ql-indent-12:not(.ql-direction-rtl) {
    padding-left: 12em;
}
.ql-editor .ql-indent-13:not(.ql-direction-rtl) {
    padding-left: 13em;
}
.ql-editor .ql-indent-14:not(.ql-direction-rtl) {
    padding-left: 14em;
}
.ql-editor .ql-indent-15:not(.ql-direction-rtl) {
    padding-left: 15em;
}

.ql-editor .ql-video{
    display: inline-block;
    margin-right: 10px;
}
.custom-file-input{
    cursor: pointer;
}
.custom-file-label{
    margin: 0;
    border-radius:0 ;
    cursor: pointer;
    content: '';
}

.custom-file-label::after{
    content:'выбрать файл' !important;    
}
.remove-file{
    color: #fff;
    background-color: red;
    cursor: pointer;
}

.doc{
    border-top: 1px solid #ccc;
    border-right: 1px solid #ccc;
    &-name{
        display: inline-block;
        margin-left: 10px;
    }
}

.doc-list .doc:last-child{
    border-bottom: 1px solid #ccc;
}

.page-admin-block .quill-editor iframe {
    pointer-events: none;
}


</style>