<template>
  <div v-if="product" class="top-header">     
    <div class="bg-white py-6 shadow">
    <b-container>
      <b-row>
        <b-col cols="12" md="12" lg="8">
          <div :class="{'modal-slider': show_modal}">
            <div class="modal-close" v-if="show_modal" @click="showModal(false)"><b-icon icon="X"></b-icon></div>
            <div class="slider-block">              
          <b-carousel v-if="product.colors"
        id="carousel-no-animation"
        :controls="imagesfromproduct.length > 1"
        :indicators="imagesfromproduct.length > 1"
        :interval="0"
      >
      
        <b-carousel-slide
          v-for="image in imagesfromproduct"
          :key="image.id"
        >
        <img slot="img" class="d-block img-fluid w-100"
             :src="download(image.id)" alt="image slot" @click="showModal(true)">  
      </b-carousel-slide>

      </b-carousel>
    </div>
  </div>
        </b-col>
        <b-col>
            <h1 class="mt-3 mt-lg-0">{{product.name}}</h1>
            <hr>
            <div class="colors-list" >
          <div class="d-inline-block p-2 colors-list-elem" @click="changeCurentColor(index)" v-for="(color, index) in product.colors" :key="color.id" :class="{active: index==current_color && !show_interior}">            
            <div class="colors-list-color d-inline-block align-middle border" :style="{backgroundColor:color.color}"></div>
            <div class="d-inline-block align-middle ml-2">{{color.name}}</div> 
          </div>
        </div>
        <hr v-if="product.images_interior.length>0">
        <div class="colors-list" v-if="product.images_interior.length>0">
          <div class="d-inline-block p-2 colors-list-elem shadow-green border" @click="showInterior()" :class="{active: show_interior}">            
            <div class="d-inline-block align-middle">Фото салона</div> 
          </div>
        </div>
            <hr>
            <div class="my-2 ql-editor ql-viewer" style="padding-bottom: 60px;" v-html="product.description"></div>
            <h4 class="price-big-card">
              <b>  {{ pricefromproduct }} </b> <span class="ql-editor  ql-viewer ql-inline" v-if="settings.valutacatalog" v-html="settings.valutacatalog"></span>                      
            </h4> 
        </b-col>
      </b-row>
    </b-container> 
  </div>
  <div class="py-3 bg-custom" v-if="product.full_description">
    <b-container> 
    <div class="py-3 px-md-4 px-3 bg-white my-5 shadow" >
        <div class="my-2 ql-editor ql-viewer" v-html="product.full_description" v-if="show_full_desc"></div> 
        <div class="my-2 ql-editor ql-viewer" v-html="full_cut_desc" v-else></div> 
        <div v-if="product.full_description != full_cut_desc" class="text-right">
          <div v-if="show_full_desc">
          <b-button variant="link" @click="showFullDesc()">скрыть</b-button>          
          </div>
          <div v-else>
          <b-button variant="link" @click="showFullDesc()">показать больше</b-button>          
          </div>
        </div>
        
      </div> 
    </b-container>   
  </div>
    <b-container> 
       
      <div v-if="product.configurations.length>0" class="my-5 pt-4">
        <h2 class="text-center mb-4"><b>КОМПЛЕКТАЦИИ</b></h2>                
        <b-row>
          <b-col cols="12" md="6" lg="4" v-for="configuration in product.configurations" :key="configuration.id" class="mb-5">
            <hr>
            <h4 class="px-3">
              <b>{{configuration.name}}</b>
            </h4>
            <hr>
            <div class="my-2 ql-editor ql-viewer" v-html="configuration.description"></div>
            <h4 class="px-3 price-big-card position-static my-3 w-100">
              <b>{{ priceformat(configuration.price) }} </b> <span class="ql-editor  ql-viewer ql-inline"  v-if="settings.valutacatalog" v-html="settings.valutacatalog"></span>          
            </h4> 
            
          </b-col>
        </b-row>     
      </div>
           
    </b-container>     
    <div class="bg-white shadow mt-5" v-if="product.link_frame">
    <b-container class="p-0 p-md-3">  
    <h2 class="text-center py-3 m-0"><b>САЛОН 360&#176;</b></h2>
      <div style="overflow-x: auto; max-width: 100%">
        <iframe width="100%" class="mb-5 frame-360" :src="product.link_frame"></iframe> 
      </div>
    </b-container>
      </div>         
    </div>   
 
</template>
<script>
import Authentication from "@/components/pages/Authentication";

export default {
  props:['settings'],
    data() {
    return {
      product:null, 
      current_color: 0,
      show_modal:false,
      show_full_desc: false,
      show_interior:false
    };
  },
  computed:{
    full_cut_desc(){
      const elem = document.createElement("div");
      elem.innerHTML = this.product.full_description
      while (elem.children.length > 1) {
        elem.removeChild(elem.lastChild);
      }
      return elem.innerHTML
    },
    imagesfromproduct(){     
      if(this.show_interior){
        if(this.product.images_interior.length>0){
          return this.product.images_interior    
        }else{
          return false
        }   
      }else{
        if(this.product.colors.length>0){
          return this.product.colors[this.current_color].images    
        }else{
          return false
        }   
      }
    },
    pricefromproduct(){
      if(this.product.configurations.length==0){
        return 0
      }
      return  this.priceformat(Math.min(...this.product.configurations.map(el=>el.price)))
    },
  },
  created() {
    this.getProduct();
  },
  methods: {  
    showInterior(){
      this.show_interior=true
    },
    showFullDesc(){
      this.show_full_desc = !this.show_full_desc
    },
    priceformat(price){
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    }, 
    download(id, download = false) {
      return Authentication.download(id, download);
    },   
    changeCurentColor(index){
      this.show_interior=false
      this.current_color = index
    },
    showModal(show){
      this.show_modal = show
    },
    async getProduct() {
      let { data } = await Authentication.sendRequestNotAuth(
        this,
        "/api/v1/product",
        "post",
        { id: this.$route.params.id }
      );
      if (data) {
        this.product = data;
      }
    },
  },
};
</script>
<style lang="scss">  
.bg-custom{
  background: radial-gradient(#ffffff 50%, #797979 100%);
}
 .colors-list{
  .active{
      border: 2px solid $color2;
      &.border{
        border: 2px solid $color2 !important;
        box-shadow: none;
      }
   }
  &-color{
    width: 20px;
    height: 20px;  
 }  
 &-elem{
  cursor: pointer;
 }
}
.price-big-card{
  border-top: 2px solid $color2;
  border-bottom: 2px solid $color2;
  text-align: center;
  padding: 10px;
  position: absolute;
  width:calc(100% - 30px);
  bottom: 0;
  margin: 0;
}
.frame-360{
  height: 99vw;
  max-height: 600px;
}
.slider-block{
    cursor: pointer;
  }
.modal-slider{
  position: fixed;
  top:0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000000cc;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-close{
      font-size: 50px;
      cursor: pointer;
      color:#fff;
      line-height: 1;
      background-color: rgba($color1, 0.8);
      z-index: 1002;
      position: absolute;
      top:10px;
      right: 30px;
    }
  .slider-block{
    position: relative;    
    max-width: 1200px;
  }
}  
@media (max-width: 767px) {
  .frame-360{
    min-width: 480px;
  }
  .modal-slider .modal-close{
    right: 5px;
  }
}

</style>